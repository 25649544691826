<template>
  <ul>
    <li class="year" v-for="layer in ['modis', 'viirs']" :key="layer">
      <q-radio v-model="firesLayer" :val="layer" :label="layer" color="accent" size="xs" />
    </li>
  </ul>

  <FlatpickrComponent
    v-if="firesDateFrom && firesDateTo"
    ref="datePicker"
    :dateInit="new Intl.DateTimeFormat('sv-SE').format(new Date())"
    :maxDate="firesDate && firesDate[0] && new Date(firesDate[0]).fp_incr(14) <= new Date() ? new Date(firesDate[0]).fp_incr(14) : new Date()"
    :minDate="firesDate && firesDate[1] ? new Date(firesDate[1]).fp_incr(-14) : null"
    range
    placeholder="Выберите период"
    @update="(value) => { firesDate = value }"
  />
</template>

<script setup>
import axios from 'axios'
import { ref, defineProps, defineEmits, watch, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import FlatpickrComponent from '@/components/common/datepicker/Flatpickr'

const $q = useQuasar()
const props = defineProps(['map', 'layersCheckedValues', 'beforeLayerId'])
const emits = defineEmits(['setLayersChecked'])

const firesLayer = ref('modis')
const firesDateFrom = ref('')
const firesDateTo = ref('')
const firesDate = ref('')

const hideFireLayer = () => {
  if (props.layersCheckedValues.includes('fire') && props.map.getSource('fire_data') && props.map.getLayer('fire')) {
    props.map.removeLayer('fire')
    props.map.removeSource('fire_data')
  }
}

const showFireLayer = () => {
  const dateFrom = firesDate.value && firesDate.value[0] ? firesDate.value[0] : firesDateFrom.value
  const dateTo = firesDate.value && firesDate.value[1] ? firesDate.value[1] : firesDateTo.value

  hideFireLayer()

  if (props.layersCheckedValues.includes('fire') && props.map.getZoom() >= 10) {
    props.map.addSource('fire_data', {
      type: 'raster',
      tiles: [
        `https://firms.modaps.eosdis.nasa.gov/mapserver/wms/fires/0725c12bb6af166e92c8dc34f41eac55?REQUEST=GetMap&layers=fires_${firesLayer.value}&WIDTH=512&HEIGHT=512&TIME=${dateFrom}/${dateTo}&BBOX={bbox-epsg-3857}&srs=EPSG:3857`,
      ]
    } )

    props.map.addLayer({
      'id': 'fire',
      'type': 'raster',
      'source': 'fire_data',
    }, props.beforeLayerId)
  }
}

const fireLayerHandler = () => {
  const currentZoom = props.map.getZoom()

  if (currentZoom < 10 && props.layersCheckedValues.includes('fire')) {
    hideFireLayer()
  } else if (currentZoom >= 10 && props.layersCheckedValues.includes('fire') && !props.map.getSource('fire_data')) {
    showFireLayer()
  }

  if (currentZoom >= 10 && props.layersCheckedValues.includes('fire')) {
    axios.get('https://firms.modaps.eosdis.nasa.gov/mapserver/mapkey_status/?MAP_KEY=0725c12bb6af166e92c8dc34f41eac55')
      .then((res) => {
        const limit = res.data?.transaction_limit
        const currentTransactions = res.data?.current_transactions
        if (limit && currentTransactions) {
          if (limit - currentTransactions < 2000) {
            emits('setLayersChecked', props.layersCheckedValuesfilter((layer) => layer.name !== 'fire_data'))
            $q.notify({
              type: 'negative',
              position: 'bottom',
              message: 'Превышен лимит для слоя "Карта пожаров", повторите через 10 минут',
              timeout: 5000
            })
          }
        }
      })

  }
}

onMounted(() => {
  firesDateFrom.value = new Intl.DateTimeFormat('sv-SE').format(new Date(Date.now() - 24 * 60 * 60 * 1000))
  firesDateTo.value = new Intl.DateTimeFormat('sv-SE').format(new Date())
})

watch(() => props.layersCheckedValues, (value) => {
  if (!value.includes('fire')) {
    firesLayer.value = 'modis'
    props.map.off('moveend', fireLayerHandler)
  }

  if (value.includes('fire')) {
    props.map.on('moveend', fireLayerHandler)
  }
})

watch(firesLayer, () => {
  showFireLayer()
})

watch(firesDate, (value) => {
  if (props.map.getZoom() >= 10 && value && value[0] && value[1]) {
    if (props.layersCheckedValues.includes('fire') && props.map.getSource('fire_data') && props.map.getLayer('fire')) {
      props.map.removeLayer('fire')
      props.map.removeSource('fire_data')
    }
    props.map.addSource('fire_data', {
      type: 'raster',
      tiles: [
        `https://firms.modaps.eosdis.nasa.gov/mapserver/wms/fires/0725c12bb6af166e92c8dc34f41eac55?REQUEST=GetMap&layers=fires_${firesLayer.value}&WIDTH=512&HEIGHT=512&TIME=${value[0]}/${value[1]}&BBOX={bbox-epsg-3857}&srs=EPSG:3857`,
      ]
    } )

    props.map.addLayer({
      'id': 'fire',
      'type': 'raster',
      'source': 'fire_data',
    }, props.beforeLayerId)
  }
})
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.year {
  margin-bottom: 0;
  margin-left: 15px;


  label {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 5px;
  }

  &__label {
    position: relative;
    padding-left: 20px;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: -1px;
      width: 12px;
      height: 12px;
      border: 1px solid $color-active;
      border-radius: 50%;
    }
  }

  input:checked + &__label::after {
      position: absolute;
      content: '';
      left: 3px;
      top: 2px;
      width: 6px;
      height: 6px;
      background-color: $color-active;
      border-radius: 50%;
  }
}
</style>
