<template>
  <MapControlButton
    :isActive="showMenu"
    title="Тип карты"
    @onClick="showHideMenu"
  >
    <svg class="layers" width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 19.135L0 12.135L1.62 10.875L8.99 16.605L16.37 10.865L18 12.135L9 19.135ZM9 14.865L1.63 9.13499L0 7.86499L9 0.86499L18 7.86499L16.36 9.13499L9 14.865Z" fill="currentColor" />
    </svg>
    <div v-if="showMenu" class="layer-list" aria-label="Меню выбора типа карты">
      <div class="layer-list-menu">
        <ul>
          <li
            v-for="(item, index) in mapVariants"
            :key="index"
            :class="item.select ? 'active' : ''"
            @click="selectMenuItem(index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="layer-list-triangle"></div>
    </div>
  </MapControlButton>
</template>
<script setup>
import { ref, onMounted, watch, defineProps } from 'vue'
import MapControlButton from './MapControlButton'

const props = defineProps(['map'])

const mapVariants = ref([
  {
    id: 'streets',
    name: 'Схема',
    select: false,
  },
  {
    id: 'satellite',
    name: 'Спутник',
    select: false,
  },
  {
    id: 'hybrid',
    name: 'Гибрид',
    select: true,
  },
  {
    id: 'background-1',
    name: 'Черный фон',
    select: false,
  }
])

const showMenu = ref(false)

const showHideMenu = () => {
  showMenu.value = !showMenu.value
}

const mapSetMapStyle = (layer) => {
  mapVariants.value.forEach((index) => {
    props.map.setLayoutProperty(index.id, 'visibility', 'none')
  })
  props.map.setLayoutProperty(mapVariants.value[layer].id, 'visibility', 'visible')
}

const selectMenuItem = (indexArr) => {
  localStorage.setItem('mapStyle', mapVariants.value[indexArr].id)
  mapVariants.value[indexArr].select = true
  mapSetMapStyle(indexArr)
  mapVariants.value.forEach((el, i) => {
    (i !== indexArr) ? el.select = false : ''
  })
  showHideMenu()
}

onMounted(() => {
  const activeLayer = localStorage.getItem('mapStyle')
  if (activeLayer) {
    const indexVariant = mapVariants.value.findIndex((layer) => layer.id === activeLayer)
    mapVariants.value = mapVariants.value.map((variant, index) => ({
      ...variant,
      select: indexVariant === index
    }))
  }
})

watch(() => props.map, (value) => {
  if (value) {
    value.on('load', () => {
      mapVariants.value.forEach((type) => {
        props.map.setLayoutProperty(type.id, 'visibility', type.select ? 'visible' : 'none')
      })
    })
  }
})
</script>
<style scoped lang="scss">
.layer-list {
  border-radius: 4px;

  .layer-list-menu {
    width: 133px;
    background: #FFFFFF;
    border-radius: 4px;
    position: absolute;
    left: -148px;
    top: -45px;
    display: flex;
    padding: 10px 0;

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 0;
      width: 100%;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        color: #2F3E4D;
        padding: 12px 16px;

        &:first-child {
          border-radius: 4px 4px 0 0;
        }

        &.active {
          color: $color-active;
        }

        &:hover {
          background: #F7F9FC;
        }
      }
    }
  }

  .layer-list-triangle {
    height: 50%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 13px solid #ffffff;
    position: absolute;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
