import ApiService from '@/services'

const actions = {
  fetchFields({ commit }, hash) {
    commit('setIsLoadingFields', true)
    commit('setFields', [])

    return new Promise((resolve, reject) => {
      ApiService.fields.getFields(hash)
        .then((response)=> {
          if (response?.data) {
            // const fields = response.data.map((field) => ({
            //   ...field,
            //   geojson: field?.geojson?.geometry ? field.geojson : {
            //     type: 'Feature',
            //     geometry: field.geojson
            //   }
            // }))
            commit('setFields', response.data)
          }
          commit('setIsLoadingFields', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFields', false)
          reject(error)
        })
    })
  },
  fetchDeletedFields({ commit }, hash) {
    commit('setIsLoadingFields', true)
    commit('setFields', [])

    return new Promise((resolve, reject) => {
      ApiService.fields.getDeletedFields(hash)
        .then((response)=> {
          commit('setFields', response.data)
          commit('setIsLoadingFields', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFields', false)
          reject(error)
        })
    })
  },
  fetchHistory({ commit }, { id }) {
    commit('setIsLoadingFields', true)
    commit('setHistory', [])

    return new Promise((resolve, reject) => {
      ApiService.fields.getFieldHistory(id)
        .then((response)=> {
          commit('setHistory', response.data)
          commit('setIsLoadingFields', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFields', false)
          reject(error)
        })
    })
  },
  fetchTileField({ commit }, id) {
    commit('setIsLoadingFields', true)
    commit('setActiveTileField', null)

    return new Promise((resolve, reject) => {
      ApiService.fields.getField(id)
        .then((response)=> {
          commit('setActiveTileField', response.data)
          commit('setIsLoadingFields', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFields', false)
          reject(error)
        })
    })
  },
  fetchSectionGeo({ commit }, hash) {
    commit('setIsLoadingFields', true)
    commit('setPolygon', null)

    return new Promise((resolve, reject) => {
      ApiService.map.getGeoPolygon(hash)
        .then((response)=> {
          if (response.data && response.data?.geojson && response.data?.hash && response.data?.layer && response.data?.id) {
            commit('setPolygon', {
              type: 'Feature',
              geometry: response.data.geojson,
              properties: {
                hash: response.data.hash,
                layer: response.data.layer,
                name: response.data.layer,
                id: response.data.id,
                cadastralNumber: response.data?.cadastralNumber
              }
            })
          }
          // commit('setActiveTileField', response.data)
          commit('setIsLoadingFields', false)
          resolve(response.data)
        })
        .catch((error) => {
          commit('setIsLoadingFields', false)
          reject(error)
        })
    })
  }
}

const mutations = {
  setFields(state, data) {
    state.fields = data
  },
  setView(state, data) {
    state.view = data
  },
  setIsShowFields(state, data) {
    state.isShowFields = data
  },
  setEditingField(state, data) {
    state.editingField = data
  },
  setDeletingField(state, data) {
    state.deletingField = data
  },
  setCreatingField(state, data) {
    state.creatingField = data
  },
  setIsLoadingFields(state, data) {
    state.isLoadingFields = data
  },
  setActiveField(state, data) {
    state.activeField = data
  },
  setEditingAttributesField(state, data) {
    state.editingAttributesField = data
  },
  setEditingStatusField(state, data) {
    state.editingStatusField = data
  },
  setUndeletingField(state, data) {
    state.undeletingField = data
  },
  setHistory(state, data) {
    state.history = data
  },
  setFieldColor(state, data) {
    state.fieldColor = data
  },
  setGroupEditingFields(state, data) {
    state.groupEditingFields = data
  },
  setFieldOverlaps(state, data) {
    state.fieldOverlaps = data
  },
  setFieldOtherLayersOverlaps(state, data) {
    state.fieldOtherLayersOverlaps = data
  },
  setIsShowFieldOverlaps(state, data) {
    state.isShowFieldOverlaps = data
  },
  setPeriod(state, data) {
    state.periodId = data
  },
  setActiveTileField(state, data) {
    state.activeTileField = data
  },
  setPolygon(state, data) {
    state.sectionGeo = data
  },
  setIsFiltredFields(state, data) {
    state.isFiltredFields = data
  },
  setSectionHash(state, data) {
    state.sectionHash = data
  },
  setSplitingField(state, data) {
    state.splitingField = data
  },
  setNewFieldsFromSplit(state, data) {
    state.newFieldsFromSplit = data
  },
  setSectionsByField(state, data) {
    state.sectionsByField = data
  },
  setSectionPolygonForField(state, data) {
    state.sectionPolygonForField = data
  },
  setSectionPointOfPolygonForField(state, data) {
    state.sectionPointOfPolygonForField = data
  },
  setIsCreatedField(state, data) {
    state.isCreatedField = data
  },
  setIsUpdatedPolygonField(state, data) {
    state.isUpdatedPolygonField = data
  },
  setJoiningFields(state, data) {
    state.joiningFields = data
  },
  setJoiningFieldsPolygon(state, data) {
    state.joiningFieldsPolygon = data
  },
  setClearedPolygon(state, data) {
    state.clearedPolygon = data
  }
}

const state = () => ({
  fields: [],
  view: 'default', // 'deleted'
  isShowFields: false,
  activeField: null,
  creatingField: null,
  editingField: null,
  deletingField: null,
  undeletingField: null,
  editingAttributesField: null,
  editingStatusField: null,
  isLoadingFields: false,
  history: [],
  fieldColor: '#1589FF',
  groupEditingFields: [],
  fieldOverlaps: [],
  fieldOtherLayersOverlaps: [],
  isShowFieldOverlaps: false,
  periodId: null,
  activeTileField: null,
  sectionGeo: null,
  isFiltredFields: false,
  sectionHash: null,
  splitingField: null,
  newFieldsFromSplit: [],
  sectionsByField: [],
  sectionPolygonForField: null,
  sectionPointOfPolygonForField: null,
  isCreatedField: false,
  joiningFields: [],
  joiningFieldsPolygon: null,
  clearedPolygon: null,
  isUpdatedPolygonField: false
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
