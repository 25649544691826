<template>
  <div class="legend" data-testid="legend">
    <ul aria-label='Легенда участков/полей/схем'>
      <li v-for="item, index in legend" :key="index" class="legend__item">
        <q-item>
          <div class="circle-border" :style='`border-color: ${item.color}`'>
            <div class="circle" :style='`background-color: ${item.color}`'></div>
          </div>
          {{ item.label }}
        </q-item>
        <q-item>
          <LayerOpacitySlider
            :ref="el => { if (el) refs[item.value] = el }"
            :map="map"
            :layer="item.value"
            @setOpacity="setOpacity"
          />
        </q-item>
      </li>
    </ul>
    <q-btn outline size="sm" label="Сбросить" color="accent" dense unelevated @click="resetOpacity" />
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import LayerOpacitySlider from './LayerOpacitySlider'

const props = defineProps({
  map: {
    type: Object,
    required: true
  },
  legend: {
    type: Array,
    required: false
  }
})
const refs = ref({})
const fieldsOpacity = ref({})

const setOpacity = ({ property, value }) => {
  fieldsOpacity.value[property] = value
  const options = [
    'case',
    ['boolean', ['feature-state', 'edited'], false], 0,
    ['boolean', ['feature-state', 'active'], false], 0.5,
    ['boolean', ['feature-state', 'focus'], false], 1,
    ['boolean', ['feature-state', 'hover'], false], 0.4
  ]

  Object.keys(fieldsOpacity.value).forEach((key) => {
    options.push(['==', ['get', 'actual_exploitation_id'], Number(key)])
    options.push(fieldsOpacity.value[key])
  })

  if (props.map.getLayer('fields_fill')) {
    props.map.setPaintProperty('fields_fill','fill-opacity', [...options, 0.2])
  }

  if (props.map.getLayer('fieldStorageLayer_fill')) {
    props.map.setPaintProperty('fieldStorageLayer_fill','fill-opacity', [...options, 0.2])
  }
}

const resetOpacity = () => {
  props.legend.forEach((item) => {
    refs.value[item.value]?.reset()
  })
}
</script>

<style scoped lang="scss">
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;

  li {
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    // font-size: 12px;
    line-height: 12px;
    color: #2F3E4D;
  }
}

.legend {
  min-width: 150px;
}

.legend__item {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}

.circle-border {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid;
  position: relative;
}

.circle {
  position: absolute;
  opacity: 0.2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
}

.legend {
  .q-item {
    width: 100%;
    min-height: auto;
    padding: 0;
  }

  .q-item__section {
    padding: 0;
  }
}
</style>
