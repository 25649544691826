<template>
  <div class="rosreestr" data-testid="form">
    <q-form ref="formRef" @validation-error="onValidateError">
      <div v-if="(type === 'create' || type === 'edit-field') && !user.region" data-testid="Муниципалитет" class="rosreestr__field rosreestr__field--validate">
        <Select
          label="Муниципалитет"
          v-model="regionId"
          :options="municipalities"
          :use-input="true"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div v-if="type !== 'edit' && type !== 'edit-field'" data-testid="Сезон посадки" class="rosreestr__field rosreestr__field--validate">
        <Select
          v-model="form.periodId"
          label="Сезон посадки"
          :options="activePeriods"
          :rules="[val => !!val || 'Обязательное поле']"
        />
      </div>
      <div v-if="type === 'edit' && form.periodId && !isCopingAttributes && isUseActualExploitation && currentInactivePeriods.length > 0" class="rosreestr__field rosreestr__copy">
        <a @click="copyAttributes">Скопировать атрибуты из другого сезона</a>
      </div>
      <div v-if="type === 'edit' && form.periodId && isCopingAttributes && isUseActualExploitation && currentInactivePeriods.length > 0" class="rosreestr__field rosreestr__copy">
        <a @click="cancelCopyAttributes">Отменить копирование атрибутов</a>
      </div>
      <div v-if="type === 'edit' && isCopingAttributes && isUseActualExploitation" data-testid="Сезон для копирования" class="rosreestr__field">
        <Select
          v-model="periodForCopy"
          label="Сезон для копирования"
          :options="currentInactivePeriods"
        />
      </div>
      <span v-if="type === 'edit' && errorCopyAttributes" class="error-org">{{ errorCopyAttributes }}</span>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Фактическая эксплуатация" @click="setDefaultValues" class="rosreestr__field">
        <Select
          v-model="form.actualExploitationId"
          label="Фактическая эксплуатация"
          :options="actualExploitations"
          :disable="checkRules('actual_exploitation_id', actualExploitationsHidden)"
        />
      </div>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Сложность ввода в эксплуатацию" @click="setDefaultValues" class="rosreestr__field">
        <Select
          v-model="form.complexityExploitationId"
          label="Сложность ввода в эксплуатацию"
          :options="complexityExploitation"
          :disable="checkRules('complexity_exploitation_id', complexityExploitationHidden)"
        />
      </div>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Состояние неиспользуемых земель" class="rosreestr__field">
        <Select
          v-model="form.efisDataUnusedLandGroupId"
          label="Состояние неиспользуемых земель"
          :options="efisDataUnusedLandGroups"
          :disable="checkRules('efis_data_unused_land_group_id', efisDataUnusedLandGroupsHidden)"
        />
      </div>
      <template v-if="type !== 'create' && type !== 'edit-field' && form.factUsers?.length > 0 && user.permissions?.includes('orgs_read')">
        <CreateOrgButton />
        <div
          v-for="(org, index) in form.factUsers"
          :key="index"
          class="rosreestr__field rosreestr__field--org rosreestr__field--validate"
          data-testid="Фактический пользователь"
          @click="setDefaultValues"
        >
          <div class="input native-input">
            <OrgsSelect
              v-model="form.factUsers[index].value"
              label="Фактический пользователь"
              :clearable="false"
              :multiple="false"
              :selectedOrgs="form.factUsers.map((item) => item.value)"
              is-validate
            />
          </div>
          <EditOrgButton :org="{ ...org, id: org.value }" />
        </div>
      </template>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Вид с/х угодий" @click="setDefaultValues" class="rosreestr__field">
        <Select
          v-model="form.fieldLandTypeId"
          label="Вид с/х угодий"
          :options="landTypes"
          :disable="checkRules('land_type_id', landTypesHidden)"
        />
      </div>
      <div v-if="type === 'create' || type === 'edit-field'" data-testid="Год ввода в оборот" class="rosreestr__field rosreestr__field--validate">
        <Select
          v-model="operationYearId"
          label="Год ввода в оборот"
          :options="operationYears"
          :rules="[val => !!val || 'Обязательное поле']"
        />
          <!-- :disable="checkRules('operation_year_id', operationYearsHidden)" -->
      </div>
      <div v-if="type === 'create' || type === 'edit-field'" data-testid="Видовой состав растительного покрова" class="rosreestr__field">
        <Select
          v-model="efisVegTypeId"
          label="Видовой состав растительного покрова"
          :options="efisVegTypes"
        />
      </div>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Вид культур" @click="setDefaultValues" class="rosreestr__field native-input">
        <Select
          v-model="form.cultureTypeId"
          label="Вид культуры"
          :options="culturesByLandType"
          :disable="checkRules('culture_type_id', cultureTypesHidden)"
          :use-input="true"
          :scroll-size="300"
        />
      </div>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Код культуры по справочнику ОКПД 2" @click="setDefaultValues" class="rosreestr__field native-input">
        <Select
          v-model="attributesEfis.efisOkpd2Id"
          label="Код культуры по справочнику ОКПД 2"
          :options="efisOkpd2Types"
          :scroll-size="700"
          :use-input="true"
          :disable="(efisOkpd2Types.length <= 1)"
        />
      </div>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="Тип севооборота" @click="setDefaultValues" class="rosreestr__field">
        <Select
          v-model="attributesEfis.efisDataCultureRotationId"
          label="Тип севооборота"
          :options="efisDataCultureRotation"
          :disable="(type === 'edit' && !isUseActualExploitation) || checkRules('efis_data_culture_rotation', efisDataCultureRotationHidden)"
        />
      </div>
      <p v-if="type !== 'edit-field' && type!== 'create'" class="additional" @click="isAdditionalEfisAttributes = !isAdditionalEfisAttributes">Дополнительные атрибуты ЕФИС
        <q-icon v-if="isAdditionalEfisAttributes" name="mdi-chevron-up" />
        <q-icon v-if="!isAdditionalEfisAttributes" name="mdi-chevron-down" />
      </p>
      <template v-if="isAdditionalEfisAttributes">
        <div v-if="type === 'edit' || type === 'group'" data-testid="Биологическая активность" class="rosreestr__field">
          <Select
            v-model="attributesEfis.efisMicrobioId"
            label="Биологическая активность"
            :options="efisMicrobioTypes"
            :disable="!isUseActualExploitation"
          />
        </div>
        <div v-if="type === 'edit' || type === 'group'" class="rosreestr__field rosreestr__field--validate">
          <span class="label-select">Плотность растительного покрова</span>
          <q-input
            v-model="attributesEfis.density"
            placeholder="Плотность растительного покрова"
            outlined
            dense
            type="number"
            :disable="!isUseActualExploitation"
            :rules="[ val => val >= 0 || 'Меньше 0' ]"
          />
        </div>
        <div v-if="type === 'edit' || type === 'group'" data-testid="Информация о Госпрограмме" class="rosreestr__field">
          <Select
            v-model="attributesEfis.efisGp"
            label="Информация о госпрограмме"
            :options="efisGpTypes"
            :disable="!isUseActualExploitation"
          />
        </div>
        <div v-if="type === 'edit' || type === 'group'" data-testid="Рекомендации по использованию земельного участка" class="rosreestr__field">
          <Select
            v-model="attributesEfis.efisRecUseId"
            label="Рекомендации по использованию земельного участка"
            :options="efisRecUseTypes"
            :disable="!isUseActualExploitation"
          />
        </div>
        <div v-if="type === 'edit' || type === 'group'" data-testid="Сидерат" class="rosreestr__field">
          <Select
            v-model="attributesEfis.siderate"
            label="Сидерат"
            :options="siderateTypes"
            :disable="!isUseActualExploitation"
          />
        </div>
        <div v-if="(type === 'edit' || type === 'group') && isPerennialCulture" data-testid="Год пользования" class="rosreestr__field">
          <Select
            v-model="attributesEfis.efisYearUseId"
            label="Год пользования"
            :options="efisYearUseTypes"
            :disable="!isUseActualExploitation"
          />
        </div>
      </template>

      <div v-if="type === 'edit' || type === 'group'" class="rosreestr__field">
        <span class="label-select">Сорт</span>
        <q-input
          v-model="attributesEfis.sort"
          placeholder="Сорт"
          outlined
          dense
          :disable="!isUseActualExploitation"
        />
      </div>

      <div v-if="type === 'edit' || type === 'group'" class="rosreestr__field">
        <span class="label-select">Вид итоговой продукции</span>
        <q-input
          v-model="attributesEfis.typeProd"
          placeholder="Вид итоговой продукции"
          outlined
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div v-if="type === 'edit' || type === 'group'" class="rosreestr__field">
        <span class="label-select">Предоставление господдержки</span>
        <q-input
          v-model="attributesEfis.suppDesc"
          placeholder="Предоставление господдержкии"
          outlined
          dense
          :disable="!isUseActualExploitation"
        />
      </div>
      <div v-if="type === 'edit' || type === 'group'" class="rosreestr__field rosreestr__field--validate">
        <span class="label-select">Размеры господдержки</span>
        <q-input
          v-model="attributesEfis.suppValue"
          placeholder="Размеры господдержкии"
          outlined
          type="number"
          dense
          :disable="!isUseActualExploitation"
          :rules="[ val => val >= 0 || 'Меньше 0' ]"
        />
      </div>
      <template v-if="isUseWinter && type === 'edit'">
        <div class="rosreestr__field">
          <q-checkbox
            v-model="isShowWinterAttributes"
            color="accent"
            label="Заполнить атрибуты озимых"
          />
        </div>
      </template>
      <template v-if="isUseWinter && isShowWinterAttributes && type === 'edit'">
        <div class="rosreestr__field">
          <span class="label-select">Озимые, погибшие до окончания сева яровых (га)</span>
          <q-input
            v-model="attributesEfis.ozRip"
            placeholder="Озимые, погибшие до окончания сева яровых (га)"
            outlined
            type="number"
            dense
          />
        </div>
        <div class="rosreestr__field">
          <span class="label-select">Озимые на зеленый корм, силос и выпас с севом яровых (га)</span>
          <q-input
            v-model="attributesEfis.ozKyar"
            placeholder="Озимые на зеленый корм, силос и выпас с севом яровых (га)"
            outlined
            type="number"
            dense
          />
        </div>
        <div class="rosreestr__field">
          <span class="label-select">Сохранившиеся озимые к концу сева яровых (га)</span>
          <q-input
            v-model="attributesEfis.ozLife"
            placeholder="Сохранившиеся озимые к концу сева яровых (га)"
            outlined
            type="number"
            dense
          />
        </div>
        <div class="rosreestr__field">
          <span class="label-select">Сохранившиеся озимые к концу сева яровых на зеленый корм, силос, для гранул и брикетов и выпас без сева яровых (га)</span>
          <q-input
            v-model="attributesEfis.ozFree"
            placeholder="Сохранившиеся озимые к концу сева яровых на зеленый корм, силос, для гранул и брикетов и выпас без сева яровых (га)"
            outlined
            type="number"
            dense
          />
        </div>
      </template>
      <div v-if="type !== 'create' && type !== 'edit-field'" data-testid="comment" class="rosreestr__field">
        <span class="label-select">Примечание</span>
        <q-input
          v-model="form.note"
          placeholder="Примечание"
          outlined
          type="textarea"
          no-error-icon
          dense
        />
      </div>
      <div v-if="userPermissions?.includes('fields_change_check_status') && type === 'group'">
        <q-checkbox
            v-model="form.toggleCheckStatus"
            label="Данные верны"
            color="accent"
        />
        <div v-if="form.toggleCheckStatus">
          Статус <b>«Данные верны»</b> будет установлен для всех выбранных полей,
          где указаны: фактическая эксплуатация, вид угодий и фактический пользователь.
        </div>
      </div>
    </q-form>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, defineExpose, reactive, ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import { useRoute } from 'vue-router'
import EditOrgButton from '@/components/common/buttons/EditOrgButton'
import CreateOrgButton from '@/components/common/buttons/CreateOrgButton'
import OrgsSelect from '@/components/common/selects/OrgsSelect'
import Select from '@/components/common/selects/Select'

import ApiService from '@/services'

const props = defineProps(['editingField', 'editingFields', 'type', 'periodId']) // 'create', 'edit', 'group', 'edit-field'
const emits = defineEmits(['close'])
const store = useStore()
const $q = useQuasar()
const route = useRoute()

const fieldsMap = {
  'culture_type_id': 'cultureTypeId',
  'field_land_type_id': 'fieldLandTypeId',
  'actual_exploitation_id': 'actualExploitationId',
  'complexity_exploitation_id': 'complexityExploitationId',
  'fact_users': 'factUsers',
  'operation_year_id': 'operationYearId',
  'efis_data_unused_land_group_id': 'efisDataUnusedLandGroupId',
  'efis_data_culture_rotation': 'efisDataCultureRotationId'
  // 'efis_veg_type_id': 'efisVegTypeId',
}

const formRef = ref()

const isShowWinterAttributes = ref(false)
const currentProps = ref({})
const isWinter = ref(false)
const isCopingAttributes = ref(false)
const currentInactivePeriods = ref([])
const periodForCopy = ref(null)
const errorCopyAttributes = ref('')
const isEmptyForm = ref(false) // group

const form = reactive({
  periodId: null,
  actualExploitationId: null,
  complexityExploitationId: null,
  factUsers: [],
  fieldLandTypeId: null,
  cultureTypeId: null,
  note: '',
  efisDataUnusedLandGroupId: null,
  toggleCheckStatus: false,
})

const regionId = ref(null)
const operationYearId = ref(null)
const efisVegTypeId = ref(null)

const attributesEfis = reactive({
  efisDataCultureRotationId: null,
  ozRip: null,
  ozKyar: null,
  ozLife: null,
  ozFree: null,
  sort: '',
  typeProd: '',
  suppDesc: '',
  suppValue: null,
  efisOkpd2Id: null,
  efisMicrobioId: null,
  efisGp: null,
  efisYearUseId: null,
  efisRecUseId: null,
  siderate: null,
  density: null,
})

const isAdditionalEfisAttributes = ref(false)

const userPermissions = computed(() => store.state.auth.user.permissions)

const isEmptyEfis = computed(() => Object.values(attributesEfis).every((value) => !value))
const isEmptyFormFields = computed(() => isEmptyEfis.value && isEmptyForm.value)
const isUseWinter = computed(() => isUseActualExploitation.value && currentPeriod.value?.isWinter)

const user = computed(() => store.state.auth.user)
const orgsDefault = computed(() => store.state.orgs.orgsByIds.filter((category) => category.isNull))
const orgsHidden = computed(() => store.state.orgs.orgsByIds.filter((category) => category.isHidden))

const operationYears = computed(() => store.state.dictionaries.operationYears.filter((item) => !item.isHidden))
const operationYearsHidden = computed(() => store.state.dictionaries.operationYears.filter((item) => item.isHidden))
const efisDataCultureRotation = computed(() => store.state.dictionaries.efisDataCultureRotation.filter((category) => !category.isHidden))
const efisDataCultureRotationDefault = computed(() => store.state.dictionaries.efisDataCultureRotation.filter((category) => category.isNull))
const efisDataCultureRotationHidden = computed(() => store.state.dictionaries.efisDataCultureRotation.filter((category) => category.isHidden))
const efisDataUnusedLandGroups = computed(() => store.state.dictionaries.efisDataUnusedLandGroups.filter((category) => !category.isHidden))
const efisDataUnusedLandGroupsDefault = computed(() => store.state.dictionaries.efisDataUnusedLandGroups.filter((category) => category.isNull))
const efisDataUnusedLandGroupsHidden = computed(() => store.state.dictionaries.efisDataUnusedLandGroups.filter((category) => category.isHidden))

const efisOkpd2Types = computed(() => {
  const options = []

  const getOption = (item) => {
    const option = {
      name: item.name,
      value: item.id,
      label: item.name,
      isNull: item.isNull,
      cannotSelect: item.allChildren?.length > 0,
      cultureTypeIds: item.cultureTypes ? item.cultureTypes.map((culture) => culture.id) : [],
    }
    options.push(option)

    if (item.allChildren?.length) {
      item.allChildren.forEach(getOption)
    }
  }

  store.state.dictionaries.efisOkpd2.filter((category) => !category.isHidden).forEach(getOption)

  const filteredOptions = form.cultureTypeId
    ? options.filter((category) => category.isNull || category.cultureTypeIds.includes(form.cultureTypeId))
    : options

  return filteredOptions.length <= 1 ? options : filteredOptions
})

const efisVegTypes = computed(() => store.state.dictionaries.efisVegType.filter((category) => !category.isHidden))
const efisGpTypes = computed(() => store.state.dictionaries.efisGp.filter((category) => !category.isHidden))
const efisMicrobioTypes = computed(() => store.state.dictionaries.efisMicrobio.filter((category) => !category.isHidden))
const efisRecUseTypes = computed(() => store.state.dictionaries.efisRecUse.filter((category) => !category.isHidden))
const efisYearUseTypes = computed(() => store.state.dictionaries.efisYearUse.filter((category) => !category.isHidden))
const siderateTypes = computed(() => store.state.dictionaries.siderateTypes)

const isUseActualExploitation = computed(() => {
  const actualExploitationUseId = actualExploitations.value.find((category) => category.name === 'Используется')
  return form.actualExploitationId === actualExploitationUseId?.value
})

const rules = computed(() => store.state.dictionaries.fieldRules)
const dictionaryPeriods = computed(() => store.state.dictionaries.periods)
// const periods = computed(() => dictionaryPeriods.value?.map(({ id, name }) => ({ value: id, label: name })))
const activePeriods = computed(() => dictionaryPeriods.value?.filter((period) => period.active))
const inactivePeriods = computed(() => dictionaryPeriods.value?.filter((period) => !period.active))
const currentPeriod = computed(() => dictionaryPeriods.value?.find((period) => period.id === form.periodId))
// const defaultPeriod = computed(() => activePeriods.value?.find((period) => period.isNow))
const periodsDefault = computed(() => store.state.dictionaries.periods.filter((period) => period.isNow))
const actualExploitations = computed(() => store.state.dictionaries.actualExploitations.filter((category) => !category.isHidden))
const actualExploitationsDefault = computed(() => store.state.dictionaries.actualExploitations.filter((category) => category.isNull))
const actualExploitationsHidden = computed(() => store.state.dictionaries.actualExploitations.filter((category) => category.isHidden))
const complexityExploitation = computed(() => store.state.dictionaries.complexityExploitation.filter((category) => !category.isHidden))
const complexityExploitationDefault = computed(() => store.state.dictionaries.complexityExploitation.filter((category) => category.isNull))
const complexityExploitationHidden = computed(() => store.state.dictionaries.complexityExploitation.filter((category) => category.isHidden))
const landTypes = computed(() => store.state.dictionaries.fieldLandTypes.filter((category) => !category.isHidden))
const landTypesDefault = computed(() => store.state.dictionaries.fieldLandTypes.filter((category) => category.isNull))
const landTypesHidden = computed(() => store.state.dictionaries.fieldLandTypes.filter((category) => category.isHidden))

const cultureTypes = computed(() => store.state.dictionaries.typeCulture
  .filter((category) => (!category.isHidden && category.isWinter === isWinter.value) || category.isNull)
)
const culturesByLandType = computed(() => {
  if (form.fieldLandTypeId) {
    return cultureTypes.value?.filter((culture) => culture.landTypes.map((item) => item.value).includes(form.fieldLandTypeId) || culture.isNull)
  }
  return cultureTypes.value
})
const cultureTypesDefault = computed(() => store.state.dictionaries.typeCulture.filter((category) => category.isNull))
const efisOkpd2Default = computed(() => store.state.dictionaries.efisOkpd2.filter((category) => category.isNull))
const cultureTypesHidden = computed(() => store.state.dictionaries.typeCulture.filter((category) => category.isHidden))
const isPerennialCulture = computed(() => {
  const currentCulture = cultureTypes.value.find((culture) => culture.value === form.cultureTypeId)
  return currentCulture?.grassPerennial ?? false
})

const municipalities = computed(() => store.state.dictionaries.regions)
const visibleEntity = computed(() => store.state.map.visibleEntity)
const activeTileField = computed(() => store.state.fields.activeTileField)
const creatingField = computed(() => store.state.fields.creatingField)

const checkEmptyFields = () => {
  const formObj = { ...form }
  delete formObj.periodId
  delete formObj.factUsers
  isEmptyForm.value = Object.values(formObj).every((value) => !value || (Array.isArray(value) && value.every((item) => !item?.value))) && form.factUsers[0]?.value === orgsDefault.value[0]?.id
}

const setCurrentEfisAttributes = (props) => {
  attributesEfis.efisDataCultureRotationId = props?.efisDataCultureRotationId ?? null
  attributesEfis.ozRip = props?.ozRip || props?.ozRip === 0 ? parseInt(props.ozRip, 10) : null
  attributesEfis.ozKyar = props?.ozKyar || props?.ozKyar === 0 ? parseInt(props.ozKyar, 10) : null
  attributesEfis.ozLife = props?.ozLife || props?.ozLife === 0 ? parseInt(props.ozLife, 10) : null
  attributesEfis.ozFree = props?.ozFree || props?.ozFree === 0 ? parseInt(props.ozFree, 10) : null
  attributesEfis.sort = props?.sort
  attributesEfis.typeProd = props?.typeProd
  attributesEfis.suppDesc = props?.suppDesc
  attributesEfis.suppValue = props?.suppValue || props?.suppValue === 0 ? parseInt(props.suppValue, 10) : null
  attributesEfis.efisMicrobioId = props?.efisMicrobioId
  attributesEfis.efisGp = props?.efisGp
  attributesEfis.efisYearUseId = props?.efisYearUseId
  attributesEfis.efisRecUseId = props?.efisRecUseId
  attributesEfis.siderate = props?.siderate
  attributesEfis.density = props?.density
  attributesEfis.efisOkpd2Id = props?.efisOkpd2Id
}

const copyAttributes = () => {
  isCopingAttributes.value = true
  const currentPeriod = activePeriods.value?.find((period) => period.id === form.periodId)

  if (currentPeriod) {
    currentInactivePeriods.value = inactivePeriods.value?.filter((period) => period.isWinter === currentPeriod.isWinter)
  }
}

const cancelCopyAttributes = () => {
  errorCopyAttributes.value = ''
  isCopingAttributes.value = false
  currentProps.value = props.editingField.props.find((prop) => prop.form.periodId === form.periodId)
}

const checkRules = (field, ids) => {
  if (ids && ids.length > 0) {
    const rulesOfHiddenValue = rules.value ? rules.value?.filter(
      (rule) => rule.result.some(
        (result) => result.field === field && Number(result.value) === ids[0].value
      )
    ) : []

    return rulesOfHiddenValue.some((rule) => {
      return rule.condition.every((c) => {
        if (c.sign === '=') {
          return form[fieldsMap[c.field]] === Number(c.value)
        }

        if (c.sign === '!=') {
          return form[fieldsMap[c.field]] !== Number(c.value)
        }
      })
    })
  }

  return false
}

const setEmptyEfisAttributes = () => {
  attributesEfis.efisDataCultureRotationId = null
  attributesEfis.ozRip = null
  attributesEfis.ozKyar = null
  attributesEfis.ozLife = null
  attributesEfis.ozFree = null
  attributesEfis.sort = null
  attributesEfis.typeProd = null
  attributesEfis.suppDesc = null
  attributesEfis.suppValue = null
  attributesEfis.efisMicrobioId = null
  attributesEfis.efisGp = null
  attributesEfis.efisYearUseId = null
  attributesEfis.efisRecUseId = null
  attributesEfis.siderate = null
  attributesEfis.density = null
  attributesEfis.efisOkpd2Id = null
}

const setCurrentValue = (formField, currentPropsField) => currentPropsField ? currentPropsField : formField

const setCurrentValues = () => {
  form.actualExploitationId = setCurrentValue(form.actualExploitationId, currentProps.value?.actualExploitationId)
  form.complexityExploitationId = setCurrentValue(form.complexityExploitationId, currentProps.value?.complexityExploitationId)
  form.fieldLandTypeId = setCurrentValue(form.fieldLandTypeId, currentProps.value?.fieldLandTypeId)
  form.cultureTypeId = setCurrentValue(form.cultureTypeId, currentProps.value?.cultureTypeId)
  form.factUsers = setCurrentValue(form.factUsers, currentProps.value?.factUsers?.map((user) => ({ ...user, value: user.id })))
  form.efisDataUnusedLandGroupId = setCurrentValue(form.efisDataUnusedLandGroupId, currentProps.value?.efisDataUnusedLandGroupId)
  form.note = currentProps.value?.note ?? ''
}

const setDefaultValue = (formValue, defaultValueArray) => {
  if (!formValue && defaultValueArray.length > 0 && defaultValueArray[0]?.value) {
    return defaultValueArray[0].value
  }
  return formValue
}

const setDefaultValues = () => {
  if (props.type === 'edit' || props.type === 'create') {
    if (props.type === 'create') {
      form.periodId = setDefaultValue(form.periodId, periodsDefault.value)
    }
    form.actualExploitationId = setDefaultValue(form.actualExploitationId, actualExploitationsDefault.value)
    form.complexityExploitationId = setDefaultValue(form.complexityExploitationId, complexityExploitationDefault.value)
    form.fieldLandTypeId = setDefaultValue(form.fieldLandTypeId, landTypesDefault.value)
    form.cultureTypeId = setDefaultValue(form.cultureTypeId, cultureTypesDefault.value)
    form.efisDataUnusedLandGroupId = setDefaultValue(form.efisDataUnusedLandGroupId, efisDataUnusedLandGroupsDefault.value)

    form.factUsers =
      !form.factUsers || form.factUsers.length < 1
        ? [{
          id: orgsDefault.value[0]?.value ?? 1,
          value: orgsDefault.value[0]?.value ?? 1,
          piece: 1, pieceTotal: 1
        }]
        : form.factUsers

    attributesEfis.efisDataCultureRotationId = setDefaultValue(attributesEfis.efisDataCultureRotationId, efisDataCultureRotationDefault.value)
  }
}

const setNullValue = (formValue, hiddenValues) =>
  hiddenValues && hiddenValues.length > 0 && formValue === hiddenValues[0].value
    ? null
    : formValue

const setNullHiddenFields = () => {
  form.fieldLandTypeId = setNullValue(form.fieldLandTypeId, landTypesHidden.value)
  form.actualExploitationId = setNullValue(form.actualExploitationId, actualExploitationsHidden.value)
  form.complexityExploitationId = setNullValue(form.complexityExploitationId, complexityExploitationHidden.value)
  form.cultureTypeId = setNullValue(form.cultureTypeId, cultureTypesHidden.value)
  form.efisDataUnusedLandGroupId = setNullValue(form.efisDataUnusedLandGroupId, efisDataUnusedLandGroupsHidden.value)
  attributesEfis.efisDataCultureRotationId = setNullValue(attributesEfis.efisDataCultureRotationId, efisDataCultureRotationHidden.value)

  form.factUsers =
    orgsHidden.value?.length > 0
      && form.factUsers.length > 0
      && form.factUsers[0].id === orgsHidden.value[0].value
      ? [{
        id: orgsDefault.value[0]?.value ?? 1,
        value: orgsDefault.value[0]?.value ?? 1,
        piece: 1,
        pieceTotal: 1
      }]
      : form.factUsers
}

const checkUserFact = () => {
  if (form.factUsers?.length > 0 && !form.factUsers[0].id && !form.factUsers[0].value) {
    form.factUsers = [{
      id: orgsDefault.value[0]?.value ?? 1,
      piece: 1,
      pieceTotal: 1,
      value: orgsDefault.value[0]?.value ?? 1,
    }]
  }

  if (form.factUsers?.length > 0 && form.factUsers[0].value) {
    form.factUsers = [{
      id: form.factUsers[0].value,
      piece: 1,
      pieceTotal: 1,
      value: form.factUsers[0].value,
    }]
  }
}

const onSuccess = () => {
  emits('close')
  store.commit('modals/setDisableModal', false)
  store.commit('mapFilter/setEditFieldCount')
  $q.notify({
    type: 'positive',
    position: 'bottom',
    message: 'Успешно',
    timeout: 5000
  })
}

const onError = (error) => {
  store.commit('modals/setDisableModal', false)

  const errorText = error?.response?.data?.message
  $q.notify({
    type: 'negative',
    position: 'bottom',
    message: errorText ?? 'Ошибка',
    timeout: 5000
  })
}

const onValidateError = (ref) => {
  ref.$el.scrollIntoView()
}

const saveEditingAttributesField = () => {
  const formEl = formRef.value
  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      ApiService.fields.updateFieldAttributes(props.editingField.id, {
        regionId: regionId.value,
        operationYearId: operationYearId.value,
        efisVegTypeId: efisVegTypeId.value
      })
        .then(() => {
          onSuccess()
          store.commit('map/setMode', 'default')

          if (visibleEntity.value === 'fields') {
            store.commit('mapFilter/setEditCount')

            if (activeTileField.value?.id) {
              store.dispatch('fields/fetchTileField', activeTileField.value.id)
            }
          }
        })
        .catch((error) => {
          onError(error)
        })
    }
  })
}

const saveEditingPropsField = () => {
  setDefaultValues()
  const formEl = formRef.value
  checkUserFact()

  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      const attributes = {
        sort: attributesEfis.sort,
        typeProd: attributesEfis.typeProd,
        suppDesc: attributesEfis.suppDesc,
        suppValue: attributesEfis.suppValue ? Number(attributesEfis.suppValue) : 0,
        efisDataCultureRotationId: attributesEfis.efisDataCultureRotationId,
        efisOkpd2Id: attributesEfis.efisOkpd2Id,
        efisMicrobioId: attributesEfis.efisMicrobioId,
        efisGp: attributesEfis.efisGp,
        efisYearUseId: attributesEfis.efisYearUseId,
        efisRecUseId: attributesEfis.efisRecUseId,
        siderate: attributesEfis.siderate,
        density: attributesEfis.density,
      }

      const efisData = {
        periodId: form.periodId,
        attributes: {}
      }

      if (isUseWinter.value) {
        efisData.attributes.ozRip = attributesEfis.ozRip
        efisData.attributes.ozKyar = attributesEfis.ozKyar
        efisData.attributes.ozLife = attributesEfis.ozLife
        efisData.attributes.ozFree = attributesEfis.ozFree
      }

      const promises = Object.keys(efisData.attributes)?.length
        ? [
          ApiService.fields.updateEfisAttributes(props.editingField.id, { ...efisData }),
          ApiService.fields.updateFieldProps(props.editingField.id, { ...form, ...attributes })
        ] : [
          ApiService.fields.updateFieldProps(props.editingField.id, { ...form, ...attributes })
        ]
      Promise.all(promises)
        .then(() => {
          onSuccess()
          store.commit('map/setMode', 'default')

          if (visibleEntity.value === 'fields') {
            store.commit('mapFilter/setEditCount')

            if (activeTileField.value?.id) {
              store.dispatch('fields/fetchTileField', activeTileField.value.id)
            }
          }

          // store.commit('fields/setIsUpdatedPolygonField', true)
        })
        .catch((error) => {
          onError(error)
        })
    }
  })
}

const saveGroupField = () => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-zucard-fieldstab-masseditfields-editfieldattributes-yes-click')
  }

  const formEl = formRef.value

  const data = {
    efisAttributes: {
      attributes: {}
    },
  }

  for (let key in form) {
    if (!Array.isArray(form[key]) && form[key]) {
      data[key] = form[key]
    }

    if (key === 'factUsers' && props.type !== 'group' && form[key].length > 0 && form[key][0].value) {
      data[key] = [{ piece: 1, pieceTotal: 1, id: form[key][0].value }]
    }

    if (key === 'factUsers' && props.type === 'group' && form[key].length > 0 && form[key][0].value && form[key][0].value !== orgsDefault.value[0]?.id) {
      data[key] = [{ piece: 1, pieceTotal: 1, id: form[key][0].value }]
    }

    const attributes = [
      'efisDataCultureRotationId',
      'sort',
      'typeProd',
      'suppDesc',
      'suppValue',
      'density',
      'efisOkpd2Id',
      'efisMicrobioId',
      'efisGp',
      'efisYearUseId',
      'efisRecUseId',
      'siderate'
    ]

    attributes.forEach((attribute) => {
      if (attributesEfis[attribute]) {
        data[attribute] = attributesEfis[attribute]
      }
    })
  }

  formEl.validate().then((result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      ApiService.fields.updateGroupFieldProps({ fieldIds: props.editingFields, periodId: form.periodId, props: data })
        .then(() => {
          onSuccess()
          store.commit('map/setMode', 'default')
          store.commit('fields/setActiveField', null)
          store.commit('fields/setGroupEditingFields', [])
          if (visibleEntity.value === 'sections') {
            store.dispatch('fields/fetchFields', route.params.id)
          }
        })
        .catch((error) => {
          onError(error)
        })
    }
  })
}

const saveCreatingField = () => {
  setDefaultValues()
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-zucard-fieldstab-createnewfield-createfieldtab-savenewfield-yes-click')
  }
  const formEl = formRef.value
  checkUserFact()

  formEl.validate().then(async (result) => {
    if (result) {
      store.commit('modals/setDisableModal', true)

      try {
        // await ApiService.fields.createField(route.params.id, { ...creatingField.value, ...form, efisDataCultureRotationId: attributesEfis.efisDataCultureRotationId })
        const res = await ApiService.fields.createField({
          ...creatingField.value,
          operationYearId: operationYearId.value,
          periodId: form.periodId,
          regionId: regionId.value,
          efisVegTypeId: efisVegTypeId.value
        })

        store.commit('map/setMode', 'default')
        store.commit('fields/setActiveField', null)
        // store.dispatch('fields/fetchTileField', res.data.id)
        store.commit('fields/setActiveTileField', { ...res.data, geojson: creatingField.value.geometry.geometry })
        store.commit('fields/setIsCreatedField', true)
        store.commit('fields/setIsUpdatedPolygonField', true)
        onSuccess()
      } catch(error) {
        onError(error)
      }
    }
  })
}

const saveField = () => {
  if (props.type === 'edit') {
    saveEditingPropsField()
  }

  if (props.type === 'group') {
    saveGroupField()
  }

  if (props.type === 'create') {
    saveCreatingField()
  }

  if (props.type === 'edit-field') {
    saveEditingAttributesField()
  }
}

watch(() => form.fieldLandTypeId, (value) => {
  if (value && form.cultureTypeId) {
    const currentCulture = culturesByLandType.value.find((item) => item.value === form.cultureTypeId)
    form.cultureTypeId = currentCulture ? form.cultureTypeId : cultureTypesDefault.value[0]?.value
  }

  if (!value) {
    form.cultureTypeId = cultureTypesDefault.value[0]?.value
  }
})

watch(() => form.cultureTypeId, (value) => {
  if (attributesEfis.efisOkpd2Id) {
    const currentEfisOkpd2 = efisOkpd2Types.value.find((item) => item.value === attributesEfis.efisOkpd2Id)
    attributesEfis.efisOkpd2Id = currentEfisOkpd2 ? attributesEfis.efisOkpd2Id : efisOkpd2Default.value[0]?.id
  }
})

watch(() => form.factUsers, (value) => {
  if (value?.length > 0) {
    store.dispatch('orgs/fetchOrgs', [ ...value.map((item) => item.value)])
  }
}, { deep: true })

watch(isUseActualExploitation, (value) => {
  if (!value) {
    form.efisDataUnusedLandGroupId = null
  }
})

watch(isUseWinter, (value) => {
  if (!value) {
    attributesEfis.ozRip = null
    attributesEfis.ozKyar = null
    attributesEfis.ozLife = null
    attributesEfis.ozFree = null
  }
})

watch(form, () => {
  setNullHiddenFields()
  if (props.type === 'group') {
    checkEmptyFields()
  }
}, { deep: true })

watch(isEmptyFormFields, (value) => {
  store.commit('modals/setDisableConfirmModal', value)
})

watch(currentProps, () => {
  setCurrentValues()
})

watch(periodForCopy, () => {
  errorCopyAttributes.value = ''
  const currentInitProps = props.editingField.props.find((prop) => prop.form.periodId === periodForCopy.value)
  if (currentInitProps?.form) {
    currentProps.value = currentInitProps.form
  } else {
    errorCopyAttributes.value = 'Атрибуты не заполнены'
  }

  if (!periodForCopy.value) {
    errorCopyAttributes.value = ''
  }
})

watch(() => form.periodId, (value) => {
  isCopingAttributes.value = false
  const currentPeriod = dictionaryPeriods.value?.find((period) => period.id === value)
  isWinter.value = currentPeriod ? currentPeriod.isWinter : false

  if (props.type === 'create') {
    form.efisDataUnusedLandGroupId = null
  }

  if (props.type === 'edit') {
    const currentInitProps = props.editingField.props.find((prop) => prop.form.periodId === form.periodId)
    if (currentInitProps?.form) {
      currentProps.value = currentInitProps.form
      setCurrentEfisAttributes(currentProps.value)
    } else {
      setDefaultValues()
      setEmptyEfisAttributes()
    }

    periodForCopy.value = null
    currentInactivePeriods.value = inactivePeriods.value.filter((period) => period.isWinter === currentPeriod?.isWinter)
  }
})

watch(orgsDefault, () => {
  if (orgsDefault.value?.length > 0 && form.factUsers.length < 1) {
    form.factUsers = [{ ...orgsDefault.value[0], piece: 1, pieceTotal: 1 }]
  }
})

watch(periodsDefault, () => { // ???
  if (periodsDefault.value?.length > 0 && props.type !== 'edit') {
    form.periodId = periodsDefault.value[0].value
  }
})

watch(operationYearId, (value) => {
  if (operationYearsHidden.value[0]?.value === value) {
    operationYearId.value = null
  }
})

onMounted(() => {
  if (cultureTypes.value.length < 1 && props.type !== 'create' && props.type !== 'edit-field') {
    store.dispatch('dictionaries/fetchTypeCulture')
  }

  if (props.type === 'edit') {
    form.periodId = props.editingField.periodId
  }

  if (props.type === 'edit-field') {
    regionId.value = props.editingField?.form?.regionId ?? null
    operationYearId.value = props.editingField?.form?.operationYear ?? null
    efisVegTypeId.value = props.editingField?.form?.efisVegTypeId ?? null
  }

  let ids = []

  if (orgsDefault.value?.length === 0) {
    ids.push(1)
  }

  if (orgsHidden.value?.length === 0) {
    ids.push(3626)
  }

  if (form.factUsers?.length) {
    ids = [ ...ids, ...form.factUsers.map((org) => org.id) ]
  }

  if (ids?.length > 0 ) {
    store.dispatch('orgs/fetchOrgs', [ ...ids])
  }

  if (props.type === 'group') {
    form.periodId = periodsDefault.value[0]?.value ?? null
  }

  if (props.type === 'create') {
    setDefaultValues()
  }

  if (props.type === 'group' && orgsDefault.value?.length && form.factUsers?.length < 1) {
    form.factUsers = [{ ...orgsDefault.value[0], piece: 1, pieceTotal: 1 }]
  }

  if (user.value.region?.value) {
    regionId.value = user.value.region.value
  }

  if (culturesByLandType.value?.length < 1) {
    store.dispatch('dictionaries/fetchTypeCulture')
  }
})

defineExpose({ saveField })
</script>

<style lang="scss" scoped>
.rosreestr {
  width: 420px;
  min-height: 250px;

  @include below('m_md') {
    width: 100%;
  }

  &__field {
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 25px;
    }

    &--org {
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;

      .input {
        max-width: 100%;
      }
    }

    &--validate:not(:last-child) {
      margin-bottom: 5px;
    }

    &--validate:has(.q-field--error) {
      margin-bottom: 10px;
    }
  }

  &__copy a {
    margin-left: auto;
    color: $color-active;
    text-align: right;
    text-decoration: underline;
  }

  &__section {

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .input {
      width: 100%;
    }
  }

  &__create-org {
    display: flex;
  }

  .input {
    flex-grow: 1;
  }

  .error-org {
    display: block;
    position: relative;
    color: $color-active;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .additional {
    color: $color-active;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
<style>
.form .disabled {
  opacity: 1 !important;
  font-style: italic;
}

.form .disabled:not(:first-child) {
  margin-top: 15px;
}
</style>
