<template>
  <div class="additional-layers" data-testid="layers">
    <div class="buttons">
      <button class="button" @click="isOpenNonFarm = !isOpenNonFarm" aria-label="Открыть/закрыть земли не с/х" title="Земли не с/х">
        <span class="additional-layers__button-text">Земли не с/х</span>
        <svg v-if="!isOpenNonFarm" width="24" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4ZM8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z" fill="#2F3E4D" />
        </svg>
        <CloseIcon v-if="isOpenNonFarm" />
      </button>
      <button class="button" @click="isOpen = !isOpen" aria-label="Открыть/закрыть слои" title="Слои">
        <span class="additional-layers__button-text">Слои</span>
        <svg v-if="!isOpen" width="24" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4ZM8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z" fill="#2F3E4D" />
        </svg>
        <CloseIcon v-if="isOpen" />
      </button>
    </div>

    <div class="additional-layers__group" v-show="isOpenNonFarm" aria-label="Список земель не с/х">
      <q-scroll-area style="height: 416px; max-width: 260px" :horizontal-thumb-style="{ opacity: 0 }" :thumb-style="{ width: '5px', opacity: '0.5' }">
        <div class="additional-layers__container-group">
          <q-list>
            <q-expansion-item label="Земли иных категорий" expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'otherlayers')" :key="source.label">
                <q-item tag="label">
                  <q-item-section>
                    <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                  </q-item-section>
                  <q-item-section avatar>
                    <div class="landuse-circle" :style="{ backgroundColor: source.layers[0].paint['fill-color']}"></div>
                  </q-item-section>
                </q-item>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <template v-for="source in sources.filter((layer) => layer.group === 'official' && layer.type === 'non-farm')" :key="source.label">
              <q-item tag="label">
                <q-item-section>
                  <q-checkbox v-model="layersChecked" :val="source" :label='source.label' color="accent" />
                </q-item-section>
              </q-item>

              <template v-if="source.value === 'zones' && layersCheckedValues.includes(source.value)">
                <div class="oopt" aria-label="ООПТ">
                  <div v-for="(item, index) in oopt" :key="index">
                    <div class="oopt__item" :style="`background: linear-gradient(${item.direction}, white 0% 20%, ${item.color} 20% 30%, white 30% 50%, ${item.color} 50% 60%, white 60% 80%, ${item.color} 80% 90%, white 90% 100%); border: 1px solid ${item.color}`">
                    </div>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </template>

              <template v-if="source.value === 'zones_rr' && layersCheckedValues.includes(source.value)">
                <div aria-label="Зоны с особыми условиями использования">
                  <div class="zones" v-for="(item, index) in zonesRr" :key="index">
                    <div class="zones__item" :style="`background-color: ${item.color}`"></div>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </template>
            </template>

            <q-item v-for="source in sources.filter((layer) => layer.group !== 'otherlayers' && layer.group !== 'official' && layer.type === 'non-farm')" :key="source.label" tag="label">
              <template v-if="source.value === 'mo_substract_fill' || source.value === 'license_fill'">
                <q-item-section>
                  <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                </q-item-section>
                <q-item-section avatar>
                  <img v-if="source.icon" :src="require(`@/assets/icons/${source.icon}.png`)" class="icon" />
                </q-item-section>
              </template>

              <template v-if="source.value !== 'mo_substract_fill' && source.value !== 'license_fill'">
                <q-item-section>
                  <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                </q-item-section>
              </template>
            </q-item>
          </q-list>
        </div>
      </q-scroll-area>
    </div>
    <div class="additional-layers__group" v-show="isOpen" aria-label="Список дополнительных слоев">
      <q-scroll-area style="height: 416px; max-width: 260px" :horizontal-thumb-style="{ opacity: 0 }" :thumb-style="{ width: '5px', opacity: '0.5' }">
        <div class="additional-layers__container-group">
          <q-list>
            <q-expansion-item label="Карта земного покрова" expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'landuse' && layer.type !== 'non-farm')" :key="source.label">
                <q-item tag="label">
                  <q-item-section>
                    <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                  </q-item-section>
                  <q-item-section avatar>
                    <div class="landuse-circle" :style="{ backgroundColor: source.layers[0].paint['fill-color']} "></div>
                  </q-item-section>
                </q-item>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <q-expansion-item label='Анализ "Нет данных"' expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'noData' && layer.type !== 'non-farm')" :key="source.label">
                <q-item tag="label">
                  <q-item-section>
                    <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                  </q-item-section>
                  <q-item-section avatar>
                    <div class="landuse-circle" :style="{ backgroundColor: source.layers[0].paint['fill-color']} "></div>
                  </q-item-section>
                </q-item>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <q-expansion-item label='Анализ "Не оцифрованные"' expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'noPolygons' && layer.type !== 'non-farm')" :key="source.label">
                <q-item tag="label">
                  <q-item-section>
                    <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                  </q-item-section>
                  <q-item-section avatar>
                    <div class="landuse-circle" :style="{ backgroundColor: source.layers[0].paint['fill-color']} "></div>
                  </q-item-section>
                </q-item>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <q-expansion-item label='ЕФИС' expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'efis' && layer.type !== 'non-farm')" :key="source.label">
                <q-item tag="label">
                  <q-item-section>
                    <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                  </q-item-section>
                  <q-item-section avatar>
                    <div class="landuse-circle" :style="{ backgroundColor: source.layers[0].paint['fill-color']} "></div>
                  </q-item-section>
                </q-item>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <q-expansion-item label="Спутники" expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'satellites' && layer.type !== 'non-farm')" :key="source.label">
                <div v-if="source.value === 'sentinel_0621_fill' && user.email === 'demo@sovhoz.ai'">
                  <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                </div>

                <div v-if="source.value !== 'sentinel_0621_fill'">
                  <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                </div>

                <template v-if="source.value === 'wms_2020' && layersCheckedValues.includes(source.value)">
                  <ul>
                    <li class="year" v-for="year in [2020, 2019, 2018]" :key="year">
                      <q-radio v-model="wmsYear" :val="year" :label="year.toString()" color="accent" size="xs" />
                    </li>
                  </ul>
                </template>

                <SentinelLayer
                  v-if="source.value === 'sentinel' && layersCheckedValues.includes(source.value)"
                  :isChecked="layersCheckedValues.includes(source.value)"
                  :map="map"
                  :beforeLayerId="beforeLayerId"
                />
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <q-expansion-item label="Официальные источники" expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'official' && layer.type !== 'non-farm')" :key="source.label">
                <q-item>
                  <q-checkbox v-model="layersChecked" :val="source" :label='source.label' color="accent" />
                </q-item>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <q-expansion-item label="Тематические карты" expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'thematic' && layer.type !== 'non-farm')" :key="source.label">
                <q-item>
                  <q-checkbox v-model="layersChecked" :val="source" :label='source.label' color="accent" />
                </q-item>

                <FireLayer
                  v-if="source.value === 'fire' && layersCheckedValues.includes(source.value)"
                  :map="map"
                  :layersCheckedValues="layersCheckedValues"
                  :beforeLayerId="beforeLayerId"
                  :setLayersChecked="(value) => layersChecked = value"
                />

                <template v-if="source.value === 'osmlanduse' && layersCheckedValues.includes(source.value)">
                  <div class="landuse" aria-label="Типы ланшафта">
                    <div v-for="(item, index) in landuse" :key="index">
                      <div class="landuse__item" :style="`background-color: ${item.color}`"></div>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </template>

                <template v-if="source.value === 'mo_dem_fill' && layersCheckedValues.includes(source.value)">
                  <div class="height" aria-label="Карта высот">
                    <div class="height__value">
                      <span>0</span>
                      <span>150</span>
                      <span>400</span>
                      <span>800</span>
                      <span>2000</span>
                    </div>
                    <div class="height__scale">
                      <div class="height__item" v-for="(item, index) in scales" :key="index" :style="`background-color: ${item}`"></div>
                    </div>
                  </div>
                </template>

                <template v-if="source.value === 'mo_soilmap_fill' && layersCheckedValues.includes(source.value)">
                  <div class="soil" aria-label="Карта почв">
                    <div v-for="(item, index) in soils" :key="index">
                      <div class="soil__item" :style="`background-color: ${item.color}`"></div>
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </template>
              </template>
              <div class="q-pa-sm"></div>
            </q-expansion-item>

            <template v-for="source in sources.filter((layer) => layer.group === 'other' && layer.type !== 'non-farm')" :key="source.label">
              <q-item v-if="source.value === 'mo_krap_fill' && (user.email === 'ustyuzhanina4205@sovhoz.ai' || user.email === 'demo@sovhoz.ai')" tag="label">
                <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
              </q-item>

              <q-item v-if="source.value === 'markers' && accessPoints" tag="label">
                <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
              </q-item>

              <template v-if="source.value !== 'mo_krap_fill' && source.value !== 'markers' && source.value !== 'sh_kugi_fill'">
                <q-item tag="label">
                  <q-item-section>
                    <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                  </q-item-section>
                  <q-item-section avatar>
                    <img v-if="source.icon" :src="require(`@/assets/icons/${source.icon}.png`)" class="icon" />
                  </q-item-section>
                </q-item>
              </template>
            </template>

            <hr>

            <p class="text-center">Карты колхозов</p>
            <q-expansion-item label="Чебулинский МО" expand-separator>
              <template v-for="source in sources.filter((layer) => layer.group === 'kolhoz-chebula' && layer.type !== 'non-farm')" :key="source.label">
                <q-item>
                  <q-checkbox v-model="layersChecked" :val="source" :label="source.label" color="accent" />
                </q-item>
              </template>
            </q-expansion-item>
          </q-list>
        </div>
      </q-scroll-area>
    </div>
  </div>

  <CadastralNumberPopup
    v-if="isShowPopupInfo && mapState !== 'edit'"
    :style="'position: fixed;' + 'top: ' + popupPosition.top + 'px; ' + 'left: ' + popupPosition.left + 'px;'"
    :cadastralNumber="hoverCN"
  />

  <FieldInfoPopup
    v-if="isShowFieldInfo && mapState !== 'edit'"
    :style="'position: fixed;' + 'top: ' + fieldPopupPosition.top + 'px; ' + 'left: ' + fieldPopupPosition.left + 'px;'"
    :fieldInfo="fieldInfo"
  />

  <MarkerInfoPopup
    v-if="isShowMarkerInfoPopup && mapState !== 'edit'"
    :marker="marker"
    @closePopup="closePopup"
    @editPin="editPin"
    @deletePin="deletePin"
    :style="'position: fixed;' + 'top: ' + markerPopupPosition.top + 'px; ' + 'left: ' + markerPopupPosition.left + 'px;'"
  />

  <div
    v-if='isShowCreateSection && mapState !== "edit"'
    class="create-section"
    :style="'position: fixed;' + 'top: ' + createSectionPopupPosition.top + 'px; ' + 'left: ' + createSectionPopupPosition.left + 'px;'"
  >
    Создать участок?
    <div class="buttons">
      <q-btn @click="createSection" label="Создать" size="sm" color="accent" outlined unelevated />
      <q-btn @click="cancelCreateSection" label="Отмена" size="sm" color="primary" outlined unelevated />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useQuasar } from 'quasar'
import CadastralNumberPopup from '@/modules/map/components/popups/CadastralNumberPopup'
import MarkerInfoPopup from '@/modules/map/components/popups/MarkerInfoPopup'
import FieldInfoPopup from '@/modules/map/components/popups/FieldInfoPopup'
import CloseIcon from '@/components/uikit/icons/CloseIcon'
import SentinelLayer from './additional/SentinelLayer'
import FireLayer from './additional/FireLayer'
import { scales, soils, landuse, oopt } from '@/modules/map/utils/layers/additional-layers-options'
import { zonesRr } from '@/modules/map/layers/additional/official'
import copy from 'copy-to-clipboard'

const layersChanges = ['mo_dem']

const $q = useQuasar()
const store = useStore()
const route = useRoute()

const props = defineProps({
  map: {
    type: Object,
    required: false
  },
  sources: {
    type: Array,
    required: true
  },
  regionId: {
    type: String,
    required: false
  }
})

const emits = defineEmits(['createSection'])

const isOpen = ref(false)
const isOpenNonFarm = ref(false)
const layersChecked = ref([])
const isHighShow = ref(false)

const hoverCN = ref(null)
const hoveredState = ref(null)
const isShowPopupInfo = ref(false)
const popupPosition = ref({
  left: 0,
  top: 0,
})
const marker = ref(null)
const markerPopupPosition = ref({
  left: 0,
  top: 0,
})

const isShowFieldInfo = ref(false)
const fieldInfo = ref(null)
const fieldPopupPosition = ref({
  left: 0,
  top: 0,
})

const isShowCreateSection = ref(false)
const createSectionPopupPosition = ref({
  left: 0,
  top: 0,
})
const creatingSection = ref(null)

const wmsYear = ref(2020)

const layersCheckedValues = computed(() => layersChecked.value.map((item) => item.value))
const accessPoints = computed(() => store.state.auth.user.permissions?.includes('section_point'))
const mapState = computed(() => store.state.map.mapState)
const beforeLayerId = computed(() => visibleEntity.value === 'sections' ? 'rosreestr_fill' : 'fields_fill')
const visibleEntity = computed(() => store.state.map.visibleEntity)
const region = computed(() => store.state.auth.user.region)
const user = computed(() => store.state.auth.user)
const points = computed(() => store.state.map.points)
const isShowMarkerInfoPopup = computed(() => store.state.map.isShowMarkerInfoPopup)

const addTarget = (name) => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    switch (name) {
    case 'sh_kugi':
      window.ym(75689863,'reachGoal','map-addlayers-kugizuschemes-click')
      break

    case 'wms_data':
      window.ym(75689863,'reachGoal','map-addlayers-sentinel2map-click')
      break

    case 'maxar_data':
      window.ym(75689863,'reachGoal','map-addlayers-maxarmap-click')
      break

    case 'pkk_data':
      window.ym(75689863,'reachGoal','map-addlayers-pkkmap-click')
      break

    case 'pkk_zones_data':
      window.ym(75689863,'reachGoal','map-addlayers-zouitmap-click')
      break

    case 'zones_data':
      window.ym(75689863,'reachGoal','map-addlayers-ooptmap-click')
      break

    case 'osmlanduse_data':
      window.ym(75689863,'reachGoal','map-addlayers-landtypesmap-click')
      break

    case 'zulf':
      window.ym(75689863,'reachGoal','map-addlayers-zulfmap-click')
      break

    case 'license_data':
      window.ym(75689863,'reachGoal','map-addlayers-zusubsoilmap-click')
      break

    case 'crops':
      window.ym(75689863,'reachGoal','map-addlayers-usedlandfromallmodel-click')
      break

    case 'rangeland':
      window.ym(75689863,'reachGoal','map-addlayers-pasturesfromallmodel')
      break

    case 'rangenodata':
      window.ym(75689863,'reachGoal','map-addlayers-potentiallandsfromnodatalands')
      break

    case 'cropsnodata':
      window.ym(75689863,'reachGoal','map-addlayers-usedlandsfromnodatalands')
      break

    case 'cropsnopolygons':
      window.ym(75689863,'reachGoal','map-addlayers-potentiallandsfromnotdigitalizedlands')
      break

    case 'rangenopolygons':
      window.ym(75689863,'reachGoal','map-addlayers-usedlandsfromnotdigitalizedlands')
      break

    case 'sentinel_data':
      window.ym(75689863,'reachGoal','map-addlayers-Sentinelvaryingdata')
      break
    }
  }
}

const editPin = () => {
  store.commit('modals/setIsShowEditPinModal', true)
  store.commit('map/setEditingPin', marker.value)
  closePopup()
}

const deletePin = () => {
  store.commit('modals/setIsShowDeletePinModal', true)
  store.commit('map/setDeletingPin', marker.value)
  closePopup()
}

const closePopup = () => {
  store.commit('map/setIsShowMarkerInfoPopup', false)
}

const onClickMarker = (e) => {
  if (e.features && e.features[0]) {
    marker.value = null
    marker.value = e.features[0].properties
  }
  markerPopupPosition.value.left = e.originalEvent.x
  markerPopupPosition.value.top = e.originalEvent.y + 10
  store.commit('map/setIsShowMarkerInfoPopup', true)
}

const addLayer = (source) => {
  if (source === 'wms_data') {
    source.layers.forEach((layer) => {
      if (props.map.getLayer(beforeLayerId.value)) {
        props.map.addLayer(layer, beforeLayerId.value)
      } else {
        props.map.addLayer(layer)
      }
    })
    return
  }

  if (layersChanges.includes(source.name)) {
    const districtId = region.value?.districtId ?? props.regionId

    if (districtId) {
      props.map.addSource(source.name, { ...source.options, tiles: [`${source.options.tiles}?ID_RAYON=${districtId}`] })
    } else {
      props.map.addSource(source.name, source.options)
    }
  } else {
    props.map.addSource(source.name, source.options)
  }

  if (source.name !== 'markers') {
    source.layers.forEach((layer) => {
      if (props.map.getLayer(beforeLayerId.value)) {
        props.map.addLayer(layer, beforeLayerId.value)
      } else {
        props.map.addLayer(layer)
      }
    })
  }

  if (source.name === 'markers' && props.map.getLayer('gl-draw-line-static.cold')) {
    source.layers.forEach((layer) => {
      props.map.addLayer(layer, 'gl-draw-line-static.cold')
    })
  }

  // this.setFilterScheme()
}

const deleteLayer = (layer, source) => {
  props.map.removeLayer(layer)
  props.map.removeSource(source)
}

const addSource = (source) => {
  if (!props.map.getSource(source.name)) {
    props.map.addSource(source.name, source.options)
    const layers = source.layers

    layers.forEach((layer) => {
      if (!props.map.getLayer(layer.id)) {
        if (props.map.getLayer(beforeLayerId.value)) {
          props.map.addLayer(layer, beforeLayerId.value)
        } else {
          props.map.addLayer(layer)
        }
      }
    })
  }
}

const deleteSource = (source) => {
  if (props.map.getSource(source.name)) {
    const layers = props.sources.find((item) => item.name === source.name).layers

    layers.forEach((layer) => {
      if (props.map.getLayer(layer.id)) {
        props.map.removeLayer(layer.id)
      }
    })

    props.map.removeSource(source.name)
  }
}

const showFieldInfo = (e) => {
  const features = props.map.queryRenderedFeatures(e.point)
  fieldInfo.value = features[0]?.properties
  isShowFieldInfo.value = !!features[0]?.properties
  fieldPopupPosition.value.left = e.originalEvent.x
  fieldPopupPosition.value.top = e.originalEvent.y + 10
  document.addEventListener('click', hideFieldInfo)
}

const hideFieldInfo = () => {
  isShowFieldInfo.value = false
  document.removeEventListener('click', hideFieldInfo)
}

const showPolygonInfo = (e) => {
  const features = props.map.queryRenderedFeatures(e.point)
  let cadastralNumber = features.length > 0 ? features[0]?.properties?.CN : null

  if (!cadastralNumber && features.length > 0 && features[0]?.properties?.REC_NUM) {
    cadastralNumber = features[0].properties.REC_NUM
  }

  isShowPopupInfo.value = !!cadastralNumber
  hoverCN.value = cadastralNumber

  popupPosition.value.left = e.originalEvent.x
  popupPosition.value.top = e.originalEvent.y + 10
}

const hidePolygonInfo = () => {
  isShowPopupInfo.value = false
}

const copyKn = (e) => {
  const features = props.map.queryRenderedFeatures(e.point)
  let cadastralNumber = features.length > 0 ? features[0]?.properties?.CN : null

  if (!cadastralNumber && features.length > 0 && features[0]?.properties?.REC_NUM) {
    cadastralNumber = features[0].properties.REC_NUM
  }

  if (cadastralNumber) {
    copy(cadastralNumber)
    $q.notify({
      type: 'positive',
      position: 'bottom',
      message: 'Кадастровый номер скопирован',
      timeout: 2000
    })
  }
}

const mouseMove = (e) => {
  showPolygonInfo(e)

  if (e.features.length > 0) {
    if (hoveredState.value) {
      props.map.setFeatureState(
        { source: e.features[0].source, sourceLayer: e.features[0].sourceLayer, id: hoveredState.value.id },
        { hover: false }
      )
    }
    hoveredState.value = e.features[0]
    props.map.setFeatureState(
      { source: hoveredState.value.source, sourceLayer: hoveredState.value.sourceLayer, id: hoveredState.value.id },
      { hover: true }
    )
  }
}

const mouseLeave = () => {
  if (hoveredState.value) {
    props.map.setFeatureState(
      { source: hoveredState.value.source, sourceLayer: hoveredState.value.sourceLayer, id: hoveredState.value.id },
      { hover: false }
    )
  }
  hoveredState.value = null
  hidePolygonInfo()
}

const onClickPolygon = (e) => {
  isShowCreateSection.value = true
  createSectionPopupPosition.value.left = e.originalEvent.x
  createSectionPopupPosition.value.top = e.originalEvent.y + 10

  if (props.map.queryRenderedFeatures(e.point)[0]) {
    const polygon = props.map.queryRenderedFeatures(e.point)[0]
    creatingSection.value = {
      id: polygon.id,
      properties: {},
      type: 'Feature',
      geometry: polygon.geometry
    }
  }
}

const cancelCreateSection = () => {
  createSectionPopupPosition.value.left = 0
  createSectionPopupPosition.value.top = 0
  isShowCreateSection.value = false
  creatingSection.value = null
}

const createSection = () => {
  emits('createSection', creatingSection.value)
  cancelCreateSection()
}

onMounted(async () => {
  const image = await props.map.loadImage(require('@/assets/icons/pattern-subsoil-use.png'))

  if (!props.map.hasImage('pattern-subsoil-use')) {
    props.map.addImage('pattern-subsoil-use', image.data)
  }
  // props.map.loadImage(
  //   require('@/assets/icons/pattern-subsoil-use.png'),
  //   (err, image) => {
  //     if (err) throw err
  //     if (!props.map.style?.imageManager?.images['pattern-subsoil-use']) {
  //       props.map.addImage('pattern-subsoil-use', image)
  //     }
  //   }
  // )

  const image2 = await props.map.loadImage(require('@/assets/icons/pattern-unsuitable.png'))

  if (!props.map.hasImage('pattern-unsuitable')) {
    props.map.addImage('pattern-unsuitable', image2.data)
  }

  // props.map.loadImage(
  //   require('@/assets/icons/pattern-unsuitable.png'),
  //   (err, image) => {
  //     if (err) throw err
  //     if (!props.map.style?.imageManager?.images['pattern-unsuitable']) {
  //       props.map.addImage('pattern-unsuitable', image)
  //     }
  //   }
  // )

  const image3 = await props.map.loadImage(require('@/assets/icons/marker-icon.png'))

  if (!props.map.hasImage('marker')) {
    props.map.addImage('marker', image3.data)
  }

  // props.map.loadImage(
  //   require('@/assets/icons/marker-icon.png'),
  //   (err, image) => {
  //     if (err) throw err
  //     if (!props.map.style?.imageManager?.images['marker']) {
  //       props.map.addImage('marker', image)
  //     }
  //   }
  // )

  const image4 = await props.map.loadImage(require('@/assets/icons/marker-icon-inactive.png'))

  if (!props.map.hasImage('marker-inactive')) {
    props.map.addImage('marker-inactive', image4.data)
  }


  // props.map.loadImage(
  //   require('@/assets/icons/marker-icon-inactive.png'),
  //   (err, image) => {
  //     if (err) throw err
  //     if (!props.map.style?.imageManager?.images['marker-inactive']) {
  //       props.map.addImage('marker-inactive', image)
  //     }
  //   }
  // )

  props.sources.forEach((source) => {
    addLayer(source)

    if (source.group === 'otherlayers') {
      source.layers.forEach((layer) => {
        props.map.on('mousemove', layer.id, mouseMove)
        props.map.on('mouseleave', layer.id, mouseLeave)
        props.map.on('click', layer.id, copyKn)
      })
    }

    if (source.name === 'yar22') {
      source.layers.forEach((layer) => {
        props.map.on('mousemove', layer.id, mouseMove)
        props.map.on('mouseleave', layer.id, mouseLeave)
        props.map.on('contextmenu', layer.id, showFieldInfo)
      })
    }

    if (source.name === 'markers') {
      source.layers.forEach((layer) => {
        props.map.on('click', layer.id, onClickMarker)
      })
    }

    if (source.name === 'cropsnopolygons' && user.value.role === 'full') {
      source.layers.forEach((layer) => {
        props.map.on('click', layer.id, onClickPolygon)
      })
    }
  })
})

watch(isOpenNonFarm, (value) => {
  if (value) {
    isOpen.value = false
  }
})

watch(isOpen, (value) => {
  if (value) {
    isOpenNonFarm.value = false
  }
})

watch(layersCheckedValues, (value) => {
  hideFieldInfo()
  if (value.includes('markers')) {
    store.dispatch('map/fetchPoints')
  }

  if (!value.includes('wms_2020')) {
    wmsYear.value = 2020
  }
})

watch(wmsYear, (value) => {
  if (layersCheckedValues.value.includes('wms_2020') && props.map.getSource('wms_data') && props.map.getLayer('wms_2020')) {
    props.map.removeLayer('wms_2020')
    props.map.removeSource('wms_data')
  }

  if (layersCheckedValues.value.includes('wms_2020') && value === 2021) {
    props.map.addSource('wms_data', {
      type: 'raster',
      tiles: [
        'https://s2maps-tiles.eu/wmts?layer=s2cloudless-2021_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}',
      ]
    } )

    props.map.addLayer({
      'id': 'wms_2020',
      'type': 'raster',
      'source': 'wms_data',
    }, beforeLayerId.value)

    return
  }

  if (layersCheckedValues.value.includes('wms_2020')) {
    props.map.addSource('wms_data', {
      type: 'raster',
      tiles: [
        `https://tiles.maps.eox.at/?service=wms&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&format=image/jpeg&layers=s2cloudless-${value}_3857&styles&tiled=true&DPI=96&MAP_RESOLUTION=96&FORMAT_OPTIONS=dpi:96&bbox={bbox-epsg-3857}`,
      ]
    } )

    props.map.addLayer({
      'id': 'wms_2020',
      'type': 'raster',
      'source': 'wms_data',
    }, beforeLayerId.value)

  }
})

watch(() => route.query.regionId, () => {
  if (isHighShow.value) {
    deleteLayer('mo_dem_fill', 'mo_dem')
    addLayer(props.sources.find((source ) => source.name === 'mo_dem'))
    props.map.setLayoutProperty('mo_dem_fill', 'visibility', 'visible')
  }
})

watch(points, () => {
  const markerSource = props.map.getSource('markers')
  markerSource.setData({
    type: 'FeatureCollection',
    features: points.value
      .filter((point) => !point.deletedAt)
      .map((point) => ({
        id: point.id,
        type: 'Feature',
        properties: {
          id: point.id,
          comment: point.comment,
          user: point.user,
          date: new Intl.DateTimeFormat('ru-RU').format(new Date(point.createdAt)),
          deletedAt: point.deletedAt,
          // icon: point.deletedAt ? 'marker-inactive' : 'marker'
          icon: 'marker'
        },
        geometry: {
          type: 'Point',
          coordinates: point.coordinates
        }
      }))
  })
})

watch(layersChecked, (value) => {
  store.commit('map/setCheckedAdditionalLayers', value)
  value.forEach((source, index) => {
    if (index !== 0) {
      deleteSource(source)
      addSource(source)
    }
  })

  props.sources.forEach((source) => {
    if (props.map.getSource(source.name) && !value.map((item) => item.name).includes(source.name)) {
      source.layers.forEach((layer) => {
        props.map.setLayoutProperty(layer.id, 'visibility', 'none')
      })
    }

    if (props.map.getSource(source.name) && value.map((item) => item.name).includes(source.name)) {
      source.layers.forEach((layer) => {
        if (layer.id === 'fire' && props.map.getZoom() < 10) {
          props.map.setLayoutProperty(layer.id, 'visibility', 'none')
        } else {
          props.map.setLayoutProperty(layer.id, 'visibility', 'visible')
        }
      })
    }
  })
})
</script>

<style lang="scss" scoped>
.additional-layers {
  width: 260px;
  z-index: 1;
  position: relative;

  &__button-text {
    @include below('m_md') {
      font-size: 10px;
    }
  }

  &__group {
    background-color: white;
    border-radius: 6px;
    border: 2px solid rgba(191, 191, 191, 0.45);
    // padding: 20px 16px;
    padding: 5px;
    padding-right: 0;
    // max-height: calc(100vh - 300px);
    max-height: 430px;

    @include below('m_lg') {
      padding: 10px;
    }

    img {
      width: 20px;
      max-width: 20px;
    }

    .icon {
      margin-right: 10px;
    }
  }

  &__container-group {
    box-sizing: border-box;
    padding: 10px 0;
  }

  .q-item {
    padding: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.soil {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 11px;

  div {
    display: flex;
    align-items: center;
  }

  &__item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.zones {
  display: flex;
  align-items: flex-start;
  font-size: 12px;

  &__item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    margin-top: 3px;
    flex-shrink: 0;
  }
}

.landuse {
  margin-bottom: 10px;
  margin-left: 15px;
  font-size: 12px;

  div {
    display: flex;
    align-items: flex-start;
  }

  &__item {
    box-sizing: border-box;
    flex-shrink: 0;
    margin-top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid $color-gray5;
    margin-right: 5px;
  }
}

.oopt {
  margin-bottom: 10px;
  margin-left: 15px;
  font-size: 12px;

  div {
    display: flex;
    align-items: flex-start;
  }

  &__item {
    box-sizing: border-box;
    flex-shrink: 0;
    margin-top: 2px;
    width: 12px;
    height: 12px;

    border: 1px solid $color-gray5;
    margin-right: 5px;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.button {
  flex-grow: 1;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border: 2px solid rgba(191, 191, 191, 0.45);

  @include below('m_md') {
    padding: 8px;
    font-size: 12px;
    margin-left: auto;
    width: 44px;
    height: 44px;
  }

  &:hover {
    color: $color-active;

    path {
      fill: $color-active;
    }

    .close-icon {
      stroke: $color-active;
    }
  }
}

.height {
  margin-bottom: 20px;

  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
  }

  &__scale {
    display: flex;
    align-items: center;
  }

  &__item {
    height: 8px;
    width: 19px;
    flex-grow: 1;
  }
}

.year {
  margin-bottom: 0;
  margin-left: 15px;

  label {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 5px;
  }

  &__label {
    position: relative;
    padding-left: 20px;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: -1px;
      width: 12px;
      height: 12px;
      border: 1px solid $color-active;
      border-radius: 50%;
    }
  }

  input:checked + &__label::after {
      position: absolute;
      content: '';
      left: 3px;
      top: 2px;
      width: 6px;
      height: 6px;
      background-color: $color-active;
      border-radius: 50%;
  }
}

.landuse-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 10px;
}

.create-section {
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  display: block;
  min-width: 150px;
  padding: 10px;
  background: #FFFFFF;
  border-radius: 4px;

  .buttons {
    display: flex;
    gap: 12px;
    margin-top: 12px;
  }
}
</style>
