<template>
  <div class="layers-panel" v-if="checkedAdditionalLayers?.length"  >
    <MapControlButton
      :isActive="isActiveModal"
      title="Слои"
      @onClick="emits('setActiveModal', 'opacity')"
    >
      <q-icon name="mdi-vector-difference" size="sm" />
    </MapControlButton>

    <div v-if="isActiveModal" class="layers-panel__modal">
      <div v-for="layer in checkedAdditionalLayers" :key="layer.label">
        {{ layer.label }}
        <LayerOpacitySlider
          v-if="layer.options.type === 'vector' && layer.layers.some((item) => item.type === 'fill')"
          :ref="el => { if (el) refs[layer.name] = el }"
          :map="map"
          :layer="layer.name"
          :initValue="layer.layers.find((item) => item.paint['fill-opacity'])?.paint['fill-opacity'] ?? 0.5"
        />
        <LayerRasterOpacitySlider
          v-if="layer.options.type === 'raster' && layer?.layers[0]?.id"
          :map="map"
          :layer="layer.layers[0].id"
          :initValue="layer.layers[0].paint['raster-opacity'] ?? 0.4"
        />
      </div>
      <q-btn
        v-if="checkedAdditionalLayers.some((layer) => layer.options.type === 'vector' && layer.layers.some((item) => item.type === 'fill'))"
        outline
        size="sm"
        label="Сбросить"
        color="accent"
        dense
        unelevated
        @click="resetOpacity"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from 'vue'
import { useStore } from 'vuex'
import LayerOpacitySlider from './LayerOpacitySlider'
import LayerRasterOpacitySlider from './LayerRasterOpacitySlider'
import MapControlButton from './MapControlButton'

defineProps(['map', 'isActiveModal'])
const emits = defineEmits(['setActiveModal'])

const store = useStore()

const refs = ref({})
const checkedAdditionalLayers = computed(() => store.state.map.checkedAdditionalLayers)

const resetOpacity = () => {
  checkedAdditionalLayers.value.forEach((item) => {
    refs.value[item.name]?.reset()
  })
}

watch(checkedAdditionalLayers, () => {
  resetOpacity()
})
</script>

<style lang="scss" scoped>
.layers-panel {
  &__modal {
    box-sizing: border-box;
    position: absolute;
    bottom: 56px;
    right: 0;
    width: auto;
    min-width: 200px;
    max-width: 240px;
    padding: 5px;
    background-color: #fff;
    border-radius: 8px;
  }
}
</style>
