<template>
  <div class="controls" data-testid="controls">
    <MapZoomControls class="zoom" :map="map" />
    <MapTypeControls :map="map" />
    <MapRuler />
    <MapSectionControls
      v-if="visibleEntity === 'sections'"
      :isCreate="isCreate"
      :isEditButton="isEditButton"
      @clickEdit="clickEdit"
      @clickCreate="clickCreate"
      @cancelCreate="$emit('cancelCreate')"
    />
    <MapFieldControls v-if='visibleEntity === "fields"' />

    <MapControlButton
      v-if="visibleEntity === 'schemes'"
      v-access="'docs_schemas_create'"
      :isActive="mode === 'create-scheme'"
      title="Создать схему"
      @onClick="switchCreateScheme"
    >
      <LandPlotsIcon :width="24" :height="24" :active="mode === 'create-scheme'" />
    </MapControlButton>

    <MapControlButton
      v-access="'section_point'"
      :isActive="isAddingPin"
      title="Создать отметку"
      @onClick="clickAddPin"
    >
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
        <path fill="currentColor" d="M255.999 0C152.786 0 68.817 85.478 68.817 190.545c0 58.77 29.724 130.103 88.349 212.017 42.902 59.948 85.178 102.702 86.957 104.494 3.27 3.292 7.572 4.943 11.879 4.943 4.182 0 8.367-1.558 11.611-4.683 1.783-1.717 44.166-42.74 87.149-101.86 58.672-80.701 88.421-153.007 88.421-214.912C443.181 85.478 359.21 0 255.999 0zm0 272.806c-50.46 0-91.511-41.052-91.511-91.511s41.052-91.511 91.511-91.511 91.511 41.052 91.511 91.511-41.053 91.511-91.511 91.511z" />
      </svg>
    </MapControlButton>

    <MapUtilityControls
      v-if="user.email === 'demo@sovhoz.ai'"
      :map="map"
      @showTileBoundaries="(value) => $emit('showTileBoundaries', value)"
      @showDrawPanel="(value) => $emit('showDrawPanel', value)"
    />

    <div class="controls-bottom">
      <MapLayersOpacityControls
        :map="map"
        :isActiveModal="activeModal === 'opacity'"
        @setActiveModal="setActiveModal"
      />

      <div class="notifications">
        <q-badge v-if="countNotifications > 0" color="accent" floating rounded>{{ countNotifications }}</q-badge>
        <MapControlButton
          :isActive="activeModal === 'notifications'"
          title="Уведомления"
          @onClick="() => setActiveModal('notifications')"
        >
          <q-icon name="mdi-message-alert-outline" size="sm" />
        </MapControlButton>
      </div>

      <MapLegend
        :map="map"
        :legend="legend"
        :isActiveModal="activeModal === 'layers'"
        @setActiveModal="setActiveModal"
      />
    </div>
  </div>

  <transition name="move">
    <NotificationsModal v-if="activeModal === 'notifications'" @close="closeNotificationsModal" />
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import localforage from 'localforage'
import LandPlotsIcon from '@/modules/map/components/icons/LandPlotsIcon'
import NotificationsModal from '@/modules/map/components/popups/NotificationsModal'
import MapZoomControls from '@/modules/map/components/controls/main/MapZoomControls'
import MapTypeControls from '@/modules/map/components/controls/main/MapTypeControls'
import MapSectionControls from '@/modules/map/components/controls/main/MapSectionControls'
import MapFieldControls from '@/modules/map/components/controls/main/MapFieldControls'
import MapUtilityControls from '@/modules/map/components/controls/main/MapUtilityControls'
import MapRuler from '@/modules/map/components/controls/main/MapRuler'
import MapLegend from '@/modules/map/components/controls/main/MapLegend'
import MapLayersOpacityControls from '@/modules/map/components/controls/main/MapLayersOpacityControls'
import MapControlButton from '@/modules/map/components/controls/main/MapControlButton'

const store = useStore()
const router = useRouter()
const route = useRoute()

const props = defineProps({
  map: {
    type: Object,
    required: false
  },
  legend: {
    type: Array,
    required: false
  },
  isLoadLayers: {
    type: Boolean,
    required: false
  },
  isEditButton: {
    type: Boolean,
    required: false
  },
  isCreate: {
    type: Boolean,
    required: false
  },
  isAddingPin: {
    type: Boolean,
    required: false
  }
})

const emits = defineEmits([
  'showTileBoundaries',
  'clickAddPin',
  'clickEdit',
  'clickCreate',
  'cancelCreate',
  'showDrawPanel'
])

const countNotifications = ref(0)
const activeModal = ref(null) // [null, 'layers', 'notifications', 'opacity']

const mapInfo = computed(() => store.state.settings.mapInfo)
const mapState = computed(() => store.state.map.mapState)
const mode = computed(() => store.state.map.mode)
const user = computed(() => store.state.auth.user)
const visibleEntity = computed(() => store.state.map.visibleEntity)
const countUpdateNotifications = computed(() => store.state.subscribes.countUpdate)

const setActiveModal = (value) => {
  activeModal.value = activeModal.value === value ? null : value
}

const closeNotificationsModal = () => {
  setActiveModal(null)

  localforage.getItem('notifications')
    .then((notifications) => {
      countNotifications.value = notifications?.length ?? 0
    })
    .catch((error) => {
      console.log(error)
    })
}

// const clickMapInfo = () => {
//   store.commit('modals/setIsShowMapInfoModal', true)
// }

const clickAddPin = () => {
  emits('clickAddPin')
}

const clickEdit = () => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-sidebar-zumassedit-click')
  }

  emits('clickEdit')
}

const clickCreate = async () => {
  if (window.location.href.includes('lk.sovhoz.tech/')) {
    window.ym(75689863,'reachGoal','map-sidebar-makenewzu-click')
  }

  if (route.params.id) {
    await router.push({ path: '/map', params: {} }).catch(() => {})
  }

  emits('clickCreate')

  if (mapState.value === 'static') {
    store.commit('map/setMapState', 'edit')
  } else if (mapState.value === 'edit') {
    emits('cancelCreate')
    store.commit('map/setMapState', 'static')
  }
}

const switchCreateScheme = () => {
  if (mode.value !== 'create-scheme') {
    if (window.location.href.includes('lk.sovhoz.tech/')) {
      window.ym(75689863,'reachGoal','map-sidebar-makenewscheme-click')
    }
    store.commit('map/setMode', 'create-scheme')
    store.commit('modals/setIsShowUploadSchemeModal', true)
    store.commit('schemes/setCurrentScheme', null)
    store.commit('schemes/setActiveScheme', null)
  } else {
    store.commit('map/setMode', 'default')
    store.commit('modals/setIsShowUploadSchemeModal', false)
    store.commit('schemes/setGeometryScheme', null)
  }
}

onMounted(() => {
  localforage.getItem('notifications')
    .then((notifications) => {
      countNotifications.value = notifications?.length ?? 0
    })
    .catch((error) => {
      console.log(error)
    })
})

watch(countUpdateNotifications, () => {
  localforage.getItem('notifications')
    .then((notifications) => {
      countNotifications.value = notifications?.length ?? 0
    })
    .catch((error) => {
      console.log(error)
    })
})
</script>

<style lang="scss" scoped>
.controls {
  position: absolute;
  right: 10px;
  z-index: 1;
  top: 62px;
  bottom: 10px;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 56px;
}

.controls-bottom {
  position: absolute;
  z-index: 1;
  display: flex;
  gap: 12px;
  right: 0;
  bottom: 0;
}

.move-enter-active,
.move-leave-active {
  transition: transform 0.5s ease;
  transform: translateX(0);
}

.move-enter-from,
.move-leave-to {
  transform: translateX(500px);
}
</style>
