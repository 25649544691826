<template>
  <MapControlButton
    :isActive="isActive"
    title="Линейка"
    @onClick="clickRuler"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 4.89543 0.89543 4 2 4H22C23.1046 4 24 4.89543 24 6V18C24 19.1046 23.1046 20 22 20H2C0.895432 20 0 19.1046 0 18V6ZM7 6L2 6V18H22V6H17V12H15V6H13V12H11V6H9V12H7V6Z" fill="currentColor" />
    </svg>
  </MapControlButton>
</template>

<script setup>
import { ref } from 'vue'
import MapControlButton from './MapControlButton'

const isActive = ref(false)

const clickRuler = () => {
  isActive.value = !isActive.value
  document.querySelector('.mapbox-ctrl-ruler button').click()
}
</script>
