<template>
  <div class="slider">
    <q-slider
      v-model="slider"
      :min="0"
      :max="1"
      :step="0.1"
      color="accent"
      thumb-size="14px"
    />
  </div>
</template>
<script setup>
import { ref, defineProps, defineExpose, watch, onMounted } from 'vue'

const slider = ref(props.initValue)

onMounted(() => {
  props.map.setPaintProperty(props.layer, 'raster-opacity', props.initValue ?? 0.2)
})

const props = defineProps({
  map: {
    type: Object,
    required: true
  },
  layer: {
    type: String,
    required: true
  },
  initValue: {
    type: Number,
    required: false,
    default: 0.2
  }
})

watch(slider, (value) => {
  props.map.setPaintProperty(props.layer, 'raster-opacity', value)
})

const reset = () => {
  slider.value = props.initValue ?? 0.2
  props.map.setPaintProperty(props.layer, 'raster-opacity', props.initValue ?? 0.2)
}

defineExpose({ reset })
</script>
<style lang="scss" scoped>
.slider {
  width: 100%;
  padding: 0 10px;
}
</style>
