<template>
  <div class="slider">
    <q-slider
      v-model="slider"
      :min="0"
      :max="1"
      :step="0.1"
      color="accent"
      thumb-size="14px"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, ref, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import additionalSources from '@/modules/map/layers/additionalLayers'

const store = useStore()

const props = defineProps({
  map: {
    type: Object,
    required: true
  },
  layer: {
    type: [String, Number],
    required: true
  },
  initValue: {
    type: [Number, Array],
    required: false,
    default: 0.2
  }
})

const emits = defineEmits(['setOpacity'])

const visibleEntity = computed(() => store.state.map.visibleEntity)

const slider = ref(0.2)
const additionalLayers = ref([])

const reset = () => {
  if (Array.isArray(props.initValue) && props.initValue?.length) {
    slider.value = props.initValue[props.initValue.length - 1]
  } else {
    slider.value = props.initValue ?? 0.2
  }
}

onMounted(() => {
  if (Array.isArray(props.initValue) && props.initValue?.length) {
    slider.value = props.initValue[props.initValue.length - 1]
  } else {
    slider.value = props.initValue ?? 0.2
  }
  additionalLayers.value = additionalSources.filter((source) => source.options.type === 'vector')
    .map((layer) => layer.name)
})

watch(slider, (value) => {
  if (additionalLayers.value.includes(props.layer) || props.layer === 'sh_kugi') {
    if (props.map.getLayer(`${props.layer}_fill`)) {
      const paintProperties = props.map.getPaintProperty(`${props.layer}_fill`, 'fill-opacity')
      if (paintProperties?.length > 1) {
        props.map.setPaintProperty(`${props.layer}_fill`,'fill-opacity', [...paintProperties.slice(0, -1), value])
      } else {
        props.map.setPaintProperty(`${props.layer}_fill`,'fill-opacity', value)
      }
    }
  }

  if (props.layer === 'accounted') {
    if (props.map.getLayer('rosreestr_fill')) {
      props.map.setPaintProperty('rosreestr_fill','fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'edited'], false], 0,
        ['boolean', ['feature-state', 'active'], false], 0.5,
        ['boolean', ['feature-state', 'focus'], false], 1,
        ['boolean', ['feature-state', 'hover'], false], 0.4,
        value,
      ])
    }

    if (props.map.getLayer('rosreestr_line')) {
      props.map.setPaintProperty('rosreestr_line','line-width', [
        'case',
        ['boolean', ['feature-state', 'focus'], false], 7,
        ['boolean', ['feature-state', 'field'], false], 3,
        value > 0.2 ? 1 : 0.5,
      ])
    }
  }

  if (props.layer === 'unaccounted') {
    ['rosreestr2', 'kugi', 'kumi', 'pais', 'federal'].forEach((layer) => {
      if (props.map.getLayer(`${layer}_fill`)) {
        props.map.setPaintProperty(`${layer}_fill`,'fill-opacity', [
          'case',
          ['boolean', ['feature-state', 'edited'], false], 0,
          ['boolean', ['feature-state', 'active'], false], 0.5,
          ['boolean', ['feature-state', 'focus'], false], 1,
          ['boolean', ['feature-state', 'hover'], false], 0.4,
          value,
        ])
      }

      if (props.map.getLayer(`${layer}_line`)) {
        props.map.setPaintProperty(`${layer}_line`,'line-width', [
          'case',
          ['boolean', ['feature-state', 'focus'], false], 7,
          ['boolean', ['feature-state', 'field'], false], 3,
          value > 0.2 ? 1 : 0.5,
        ])
      }
    })
  }

  if (visibleEntity.value === 'fields') {
    emits('setOpacity', { value, property: props.layer })
  }
})

defineExpose({ reset })
</script>

<style lang="scss" scoped>
.slider {
  width: 100%;
  padding: 0 10px;
}
</style>
