<template>
  <MapControlButton
    class="controls-empty"
    :isActive="isEmptyAreas"
    title="Пустые области на карте"
    @onClick="clickEmptyAreas"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" :class="{loading: isLoadingEmptyAreas}">
      <line x1="12" y1="2" x2="12" y2="6" />
      <line x1="12" y1="18" x2="12" y2="22" />
      <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
      <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
      <line x1="2" y1="12" x2="6" y2="12" />
      <line x1="18" y1="12" x2="22" y2="12" />
      <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
      <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
    </svg>
  </MapControlButton>

  <MapControlButton
    :isActive="isShowTileBoundaries"
    title="Режим отладки"
    @onClick="isShowTileBoundaries = !isShowTileBoundaries"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z" fill="currentColor" />
    </svg>
  </MapControlButton>

  <MapControlButton
    :isActive="isShowDrawPanel"
    title="Панель рисования"
    @onClick="isShowDrawPanel = !isShowDrawPanel"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M15,3V7.59L7.59,15H3V21H9V16.42L16.42,9H21V3M17,5H19V7H17M5,17H7V19H5" fill="currentColor" />
    </svg>
  </MapControlButton>

  <MapControlButton
    :isActive="isVisibleGeojsonPanel"
    title="Geojson"
    @onClick="store.commit('map/setIsVisibleGeojsonPanel', !isVisibleGeojsonPanel)"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6875 12.75H13.3125L12 8.90625L10.6875 12.75ZM13.7344 14.0156H10.2188L9.51562 15.9844H7.875L11.2969 6.98438H12.7031L16.0781 15.9844H14.4844L13.7344 14.0156ZM18.9844 5.01562H21V3H18.9844V5.01562ZM21 21V18.9844H18.9844V21H21ZM17.0156 18.9844V17.0156H18.9844V6.98438H17.0156V5.01562H6.98438V6.98438H5.01562V17.0156H6.98438V18.9844H17.0156ZM5.01562 21V18.9844H3V21H5.01562ZM3 3V5.01562H5.01562V3H3ZM23.0156 6.98438H21V17.0156H23.0156V23.0156H17.0156V21H6.98438V23.0156H0.984375V17.0156H3V6.98438H0.984375V0.984375H6.98438V3H17.0156V0.984375H23.0156V6.98438Z" fill="currentColor" />
      <rect x="7" y="6" width="10" height="10" fill="#ffffff" />
    </svg>
  </MapControlButton>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from 'vue'
import { useStore } from 'vuex'
import * as Turf from '@turf/turf'
import { addCreatedLayer, removeCreatedLayer } from '@/modules/map/utils/layers/created-layer'
import MapControlButton from './MapControlButton'

const store = useStore()

const props = defineProps(['map'])
const emits = defineEmits(['showTileBoundaries', 'showDrawPanel'])

const isEmptyAreas = ref(false)
const isLoadingEmptyAreas = ref(false)
const isShowTileBoundaries = ref(false)
const isShowDrawPanel = ref(false)

const isVisibleGeojsonPanel = computed(() => store.state.map.isVisibleGeojsonPanel)

const getEmptyAreas = () => {
  if (props.map && props.map.getZoom() > 12) {
    isLoadingEmptyAreas.value = true

    const rosreestrPolygons = props.map.querySourceFeatures('rosreestr', { sourceLayer: 'rosreestr' })
    const rosreestr2Polygons = props.map.querySourceFeatures('rosreestr2', { sourceLayer: 'rosreestr2' })
    const kugiPolygons = props.map.querySourceFeatures('kugi', { sourceLayer: 'kugi' })
    const kumiPolygons = props.map.querySourceFeatures('kumi', { sourceLayer: 'kumi' })
    const federalPolygons = props.map.querySourceFeatures('federal', { sourceLayer: 'federal' })
    const paisPolygons = props.map.querySourceFeatures('pais', { sourceLayer: 'pais' })
    const zlfPolygons = props.map.querySourceFeatures('zulf', { sourceLayer: 'zu_lf' })
    const substractPolygons = props.map.querySourceFeatures('mo_substract_data', { sourceLayer: 'mo_substract_fill' })
    const licensePolygons = props.map.querySourceFeatures('license_data', { sourceLayer: 'license' })

    const totalFeatures = [
      ...rosreestrPolygons,
      ...rosreestr2Polygons,
      ...kugiPolygons,
      ...kumiPolygons,
      ...paisPolygons,
      ...federalPolygons,
      ...zlfPolygons,
      ...substractPolygons,
      ...licensePolygons,
    ]

    const canvas = props.map.getCanvas()
    const w = canvas.width
    const h = canvas.height
    const cUL = props.map.unproject([0, 0]).toArray()
    const cUR = props.map.unproject([w, 0]).toArray()
    const cLR = props.map.unproject([w, h]).toArray()
    const cLL = props.map.unproject([0, h]).toArray()
    const coordinates = [cUL, cUR, cLR, cLL, cUL]
    const polygon = Turf.polygon([coordinates])

    const emptyAreas = totalFeatures
      //.filter((feature) => !feature.properties.deleted)
      .reduce((total, feature) => Turf.difference(total, feature), polygon)

    removeCreatedLayer(props.map)
    addCreatedLayer({
      polygons: [emptyAreas],
      map: props.map,
      color: 'red',
      activeColor: '#dc1c0a',
    })
  } else {
    removeCreatedLayer(props.map)
    isLoadingEmptyAreas.value = false
  }
}

const clickEmptyAreas = () => {
  if (isEmptyAreas.value) {
    isEmptyAreas.value = false

    props.map.off('zoomend', getEmptyAreas)
    props.map.off('moveend', getEmptyAreas)
    removeCreatedLayer(props.map)
    isLoadingEmptyAreas.value = false
  } else {
    isEmptyAreas.value = true
    isLoadingEmptyAreas.value = props.map.getZoom() > 12

    props.map.on('zoomend', getEmptyAreas)
    props.map.on('moveend', getEmptyAreas)
  }
}

watch(isShowTileBoundaries, (value) => {
  emits('showTileBoundaries', value)
})

watch(isShowDrawPanel, (value) => {
  emits('showDrawPanel', value)
})
</script>

<style lang="scss" scoped>
.controls-empty.active {
  svg.loading {
    animation: 2s linear infinite rotate;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
