<template>
  <div class="select">
    <q-select v-model="sentinelLayer" :options="sentinelOptions" label="Выберите слой" emit-value map-options borderless />
  </div>

  <div class="select">
    <p>Выберите период от</p>
    <div class="select-dates">
      <div class="select-year">
        <q-select v-model="sentinelYear" :options="[2024, 2023, 2022, 2021, 2020]" borderless label="Год" />
      </div>
      <div class="select-month">
        <q-select v-model="sentinelMonthFrom" :options="sentinelMonths" emit-value map-options borderless label="Месяц" />
      </div>
    </div>

    <p>до</p>
    <div class="select-dates">
      <div class="select-year">
        <q-select v-model="sentinelYear" :options="[2024, 2023, 2022, 2021, 2020]" borderless label="Год" />
      </div>
      <div class="select-month">
        <q-select v-model="sentinelMonthTo" :options="sentinelMonths" emit-value map-options borderless label="Месяц" />
      </div>
    </div>
  </div>

  <q-btn
    color="accent"
    label="Применить"
    flat
    @click="setSentinelParams"
  />
</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps(['map', 'isChecked', 'beforeLayerId'])

const sentinelOptions = [
  { value: '2_TONEMAPPED_NATURAL_COLOR', label: 'Natural Color' },
  { value: '3_NDVI', label: 'NDVI' },
]
const sentinelMonths = [
  { value: 1, label: 'Апрель' },
  { value: 2, label: 'Май' },
  { value: 3, label: 'Июнь' },
  { value: 4, label: 'Июль' },
  { value: 5, label: 'Август' },
  { value: 6, label: 'Сентябрь' },
  { value: 7, label: 'Октябрь' },
  { value: 8, label: 'Ноябрь' },
]

const sentinelLayer = ref('2_TONEMAPPED_NATURAL_COLOR') // 3_NDVI
const sentinelYear = ref(2024)
const sentinelMonthFrom = ref(2)
const sentinelMonthTo = ref(6)
const setSentinelParams = () => {
  const layer = sentinelLayer.value ?? '2_TONEMAPPED_NATURAL_COLOR'
  const year = sentinelYear.value ?? 2024
  const monthFrom = sentinelMonthFrom.value ? (sentinelMonthFrom.value + 3).toString().padStart(2, '0') : '04'
  const monthTo = sentinelMonthTo.value && sentinelMonthTo.value >= sentinelMonthFrom.value ? (sentinelMonthTo.value + 3).toString().padStart(2, '0') : monthFrom
  sentinelYear.value = year
  sentinelMonthFrom.value = Number(monthFrom) - 3
  sentinelMonthTo.value = Number(monthTo) - 3
  sentinelLayer.value = layer

  if (props.isChecked) {
    props.map.getLayer('sentinel') && props.map.removeLayer('sentinel')
    props.map.getSource('sentinel_data') && props.map.removeSource('sentinel_data')
    props.map.addSource('sentinel_data', {
      type: 'raster',
      tiles: [
        `https://services.sentinel-hub.com/ogc/wms/ed64bf38-72da-4723-9c06-568b76b8add0?REQUEST=GetMap&SERVICE=WMS&LAYERS=${layer}&WIDTH=512&HEIGHT=512&SHOWLOGO=false&TIME=${year}-${monthFrom}/${year}-${monthTo}&PRIORITY=leastCC&bbox={bbox-epsg-3857}`
      ]
    })

    props.map.addLayer({
      'id': 'sentinel',
      'type': 'raster',
      'source': 'sentinel_data',
    }, props.beforeLayerId)
  }
}
</script>

<style lang="scss" scoped>
.select {
  width: 200px;
  margin: 0 auto;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
  }
}

.select-dates {
  display: flex;
  justify-content: space-between;
}

.select-year {
  width: 80px;
}

.select-month {
  width: 100px;
}
</style>
