<template>
  <div
    v-if="(legend.length && visibleEntity === 'sections') || (fieldLegend.length && visibleEntity === 'fields') || (schemeLegend.length && visibleEntity === 'schemes')"
    class="map-legend"
    :class='{ "map-legend--mobile": isMobile, opened: isActiveModal }'
  >
    <MapControlButton
      class="controls-layer"
      v-show="isMobile"
      :isActive="isActiveModal"
      title="Слои"
      @onClick="emits('setActiveModal', 'layers')"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="currentColor" />
        <rect width="392" height="1016" transform="translate(-344 -448)" fill="white" />
        <path d="M6.98438 6.98438H21V9H6.98438V6.98438ZM6.98438 17.0156V15H21V17.0156H6.98438ZM6.98438 12.9844V11.0156H21V12.9844H6.98438ZM3 9V6.98438H5.01562V9H3ZM3 17.0156V15H5.01562V17.0156H3ZM3 12.9844V11.0156H5.01562V12.9844H3Z" fill="currentColor" />
      </svg>
    </MapControlButton>
    <Legend
      v-if="visibleEntity === 'sections'"
      :legend="legend"
      :map="map"
    />
    <Legend
      v-if="visibleEntity === 'fields'"
      :legend="fieldLegend"
      :map="map"
    />
    <Legend
      v-if="visibleEntity === 'schemes'"
      :legend="schemeLegend"
      :map="map"
    />
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from 'vue'
import { useStore } from 'vuex'
import Legend from './Legend'
import MapControlButton from './MapControlButton'

const schemeLegend = [
  { color: '#98ff98', value: 'sh_kugi', label: 'Схемы', name: 'Схемы' }
]

const props = defineProps(['legend', 'map', 'isActiveModal'])
const emits = defineEmits(['setActiveModal'])
const store = useStore()

const isMobile = ref(true)

const fieldLegend = computed(()=> store.state.dictionaries.actualExploitations)
const visibleEntity = computed(() => store.state.map.visibleEntity)
</script>

<style lang="scss" scoped>
.controls-layer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
}

.map-legend {
  &.map-legend--mobile {
    padding: 0;
    border: none;

    .legend {
      display: none;
    }
  }

  &.opened {
    .legend {
      display: block;
      position: absolute;
      bottom: 56px;
      right: 0;
      padding: 10px;
      z-index: 1;
      width: 200px;
      background: #FFFFFF;
      border: 2px solid rgba(191, 191, 191, 0.45);
      border-radius: 8px;
    }
  }

  @include below('m_lg') {
    padding: 10px;
  }

  @include below('m_md') {
    padding: 5px;
  }
}
</style>
