<template>
  <div class="controls-zoom">
    <button class="plus" @click="mapZoomIn" type="button" title="Увеличить масштаб">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 8V14H6V8H0V6H6V0H8V6H14V8H8Z" fill="currentColor" />
      </svg>
    </button>
    <button class="minus" @click="mapZoomOut" type="button" title="Уменьшить масштаб">
      <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2V0H14V2H0Z" fill="currentColor" />
      </svg>
    </button>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'

const props = defineProps(['map'])

const MIN_ZOOM = 3
const MAX_ZOOM = 18

const mapZoomIn = () => {
  const currentZoom = Math.round(props.map.getZoom())
  if (currentZoom < MAX_ZOOM) {
    props.map.zoomTo(currentZoom + 1)
  }
}

const mapZoomOut = () => {
  const currentZoom = Math.round(props.map.getZoom())
  if (currentZoom > MIN_ZOOM) {
    props.map.zoomTo(currentZoom - 1)
  }
}
</script>
<style scoped lang="scss">
.controls-zoom {
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  border: 2px solid rgba(191, 191, 191, 0.45);
  border-radius: 8px;
  position: relative;

  &::before {
    content: '';
    width: 24px;
    height: 1px;
    background-color: #F2F2F2;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.plus, .minus {
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #2F3E4D;
  border: none;

  &:hover,
  &:focus {
    color: $color-active;
  }
}
</style>
