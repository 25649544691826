const mutations = {
  setIsShowCheckStatusModal(state, data){
    state.isShowCheckStatusModal = data
  },
  setIsShowDevCheckStatusModal(state, data){
    state.isShowDevCheckStatusModal = data
  },
  setIsShowChangeModal(state, data){
    state.isShowChangeModal = data
  },
  setIsShowCreateOrgModal(state, data){
    state.isShowCreateOrgModal = data
  },
  setIsShowChangeOrgModal(state, data) {
    state.isShowChangeOrgModal = data
  },
  setIsShowChangeLayerModal(state, data){
    state.isShowChangeLayerModal = data
  },
  setIsShowChangeKNModal(state, data){
    state.isShowChangeKNModal = data
  },
  setIsShowDeleteModal(state, data){
    state.isShowDeleteModal = data
  },
  setIsShowUndeleteModal(state, data){
    state.isShowUndeleteModal = data
  },
  setIsShowOverlapsModal(state, data){
    state.isShowOverlapsModal = data
  },
  setIsShowGroupChangeModal(state, data){
    state.isShowGroupChangeModal = data
  },
  setIsShowAddSntModal(state, data) {
    state.isShowAddSntModal = data
  },
  setIsShowCreatePinModal(state, data) {
    state.isShowCreatePinModal = data
  },
  setIsShowEditPinModal(state, data) {
    state.isShowEditPinModal = data
  },
  setIsShowDeletePinModal(state, data) {
    state.isShowDeletePinModal = data
  },
  setIsShowCreateSchemeModal(state, data) {
    state.isShowCreateSchemeModal = data
  },
  setIsShowUploadSchemeModal(state, data) {
    state.isShowUploadSchemeModal = data
  },
  setIsShowChangeSchemeModal(state, data) {
    state.isShowChangeSchemeModal = data
  },
  setIsShowSolutionSchemeModal(state, data) {
    state.isShowSolutionSchemeModal = data
  },
  setIsShowKnSchemeModal(state, data) {
    state.isShowKnSchemeModal = data
  },
  setIsShowDeleteSchemeModal(state, data) {
    state.isShowDeleteSchemeModal = data
  },
  setIsShowCreateFieldModal(state, data) {
    state.isShowCreateFieldModal = data
  },
  setIsShowEditFieldModal(state, data) {
    state.isShowEditFieldModal = data
  },
  setIsShowEditAttributesFieldModal(state, data) {
    state.isShowEditAttributesFieldModal = data
  },
  setIsShowDeleteFieldModal(state, data) {
    state.isShowDeleteFieldModal = data
  },
  setIsShowCheckStatusFieldModal(state, data) {
    state.isShowCheckStatusFieldModal = data
  },
  setIsShowUndeleteFieldModal(state, data) {
    state.isShowUndeleteFieldModal = data
  },
  setIsShowGroupEditFieldModal(state, data) {
    state.isShowGroupEditFieldModal = data
  },
  setIsShowGroupEditOperationYearsModal(state, data) {
    state.isShowGroupEditOperationYearsModal = data
  },
  setIsFieldOverlapsModal(state, data) {
    state.isFieldOverlapsModal = data
  },
  setIsNotOverlapsModal(state, data) {
    state.isNotOverlapsModal = data
  },
  setIsShowEditWithoutCurrentModal(state, data) {
    state.isShowEditWithoutCurrentModal = data
  },
  setIsShowEditModal(state, data) {
    state.isShowEditModal = data
  },
  setIsShowLoadedCreateModal(state, data) {
    state.isShowLoadedCreateModal = data
  },
  setIsShowCheckPolygonModal(state, data) {
    state.isShowCheckPolygonModal = data
  },
  setIsShowCreateModal(state, data) {
    state.isShowCreateModal = data
  },
  setIsShowImageModal(state, data) {
    state.isShowImageModal = data
  },
  setIsShowFileModal(state, data) {
    state.isShowFileModal = data
  },
  setIsShowEditFilesModal(state, data) {
    state.isShowEditFilesModal = data
  },
  setIsShowDeleteFilesModal(state, data) {
    state.isShowDeleteFilesModal = data
  },
  setIsShowEfisHistoryModal(state, data) {
    state.isShowEfisHistoryModal = data
  },
  setIsShowSuccessEfisModal(state, data) {
    state.isShowSuccessEfisModal = data
  },
  // setIsShowConfirmFieldsModal(state, data) {
  //   state.isShowConfirmFieldsModal = data
  // },
  setIsShowMapInfoModal(state, data) {
    state.isShowMapInfoModal = data
  },
  setIsShowEnrichmentFieldModal(state, data) {
    state.isShowEnrichmentFieldModal = data
  },
  setDisableModal(state, data) {
    state.isDisableModal = data
  },
  setDisableConfirmModal(state, data) {
    state.isDisableConfirmModal = data
  },
  setIsShowDeleteOrgModal(state, data) {
    state.isShowDeleteOrgModal = data
  },
  setIsShowLinkOrgModal(state, data) {
    state.isShowLinkOrgModal = data
  },
  setIsShowInheriteAttributesModal(state, data) {
    state.isShowInheriteAttributesModal = data
  },
  setIsShowNonInheriteAttributesModal(state, data) {
    state.isShowNonInheriteAttributesModal = data
  },
  setIsShowSplitFieldModal(state, data) {
    state.isShowSplitFieldModal = data
  },
  setIsShowSectionInfoModal(state, data) {
    state.isShowSectionInfoModal = data
  },
  setIsShowJoinFieldsModal(state, data) {
    state.isShowJoinFieldsModal = data
  },
}

const state = () => ({
  isDisableModal: false,
  isDisableConfirmModal: false,
  isShowCheckStatusModal: false,
  isShowDevCheckStatusModal: false,
  isShowChangeModal: false,
  isShowCreateOrgModal: false,
  isShowChangeLayerModal: false,
  isShowChangeKNModal: false,
  isShowDeleteModal: false,
  isShowUndeleteModal: false,
  isShowOverlapsModal: false,
  isShowGroupChangeModal: false,
  isShowAddSntModal: false,
  isShowCreatePinModal: false,
  isShowEditPinModal: false,
  isShowDeletePinModal: false,
  isShowCreateSchemeModal: false,
  isShowUploadSchemeModal: false,
  isShowChangeSchemeModal: false,
  isShowSolutionSchemeModal: false,
  isShowKnSchemeModal: false,
  isShowDeleteSchemeModal: false,
  isShowCreateFieldModal: false,
  isShowEditFieldModal: false,
  isShowEditAttributesFieldModal: false,
  isShowDeleteFieldModal: false,
  isShowCheckStatusFieldModal: false,
  isShowUndeleteFieldModal: false,
  isShowGroupEditFieldModal: false,
  isShowGroupEditOperationYearsModal: false,
  isFieldOverlapsModal: false,
  isNotOverlapsModal: false,
  isShowEditWithoutCurrentModal: false,
  isShowEditModal: false,
  isShowLoadedCreateModal: false,
  isShowCheckPolygonModal: false,
  isShowCreateModal: false,
  isShowImageModal: false,
  isShowFileModal: false,
  isShowEditFilesModal: false,
  isShowDeleteFilesModal: false,
  isShowEfisHistoryModal: false,
  isShowSuccessEfisModal: false,
  // isShowConfirmFieldsModal: false,
  isShowMapInfoModal: false,
  isShowChangeOrgModal: false,
  isShowEnrichmentFieldModal: false,
  isShowDeleteOrgModal: false,
  isShowLinkOrgModal: false,
  isShowInheriteAttributesModal: false,
  isShowNonInheriteAttributesModal: false,
  isShowSplitFieldModal: false,
  isShowSectionInfoModal: false,
  isShowJoinFieldsModal: false
})

export default {
  namespaced: true,
  state,
  mutations,
}
