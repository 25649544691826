import * as Turf from '@turf/turf'

export const fixGeometryCollection = (geometryCollection) => {
  if (geometryCollection.type === 'Feature') {
    return geometryCollection
  }

  try {
    if (geometryCollection.type === 'GeometryCollection') {
      const geometries = geometryCollection?.geometries?.filter((geometry) => geometry.type === 'Polygon' || geometry.type === 'MultiPolygon')

      let geojson

      if (geometries.length > 1) {
        const multiPolygon = Turf.multiPolygon(geometries.map((feature) => feature.coordinates))

        geojson = multiPolygon.geometry
      } else if (geometries.length === 1) {
        geojson = geometries[0]
      } else {
        geojson = geometryCollection
      }
      return geojson
    }

    return geometryCollection
  } catch (err) {
    return geometryCollection
  }
}
