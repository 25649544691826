<template>
  <div class="container">
    <q-scroll-area :style="maintenanceNotice ? 'height: calc(100vh - 68px - 50px - 40px)' : 'height: calc(100vh - 68px - 50px)'" :thumb-style="{ width: '5px', opacity: '0.5' }">

      <div class="upload">
        <div class="polygon" v-for="polygon, index in polygons" :key="index">
          <div class="color" :style="{backgroundColor: polygon.properties.color}"></div>
          <span>{{ polygon.comment }}</span>
          <button class="button" @click="findPolygon(index)">Найти</button>
          <button class="button" v-if="!polygon.properties.isHidden" @click="hidePolygon(index)">Скрыть</button>
          <button class="button" v-if="polygon.properties.isHidden" @click="showPolygon(index)">Показать</button>
          <button class="button" @click="deletePolygon(index)">Удалить</button>
        </div>

        <label for="polygon">Полигон</label>
        <textarea v-model="polygon" rows="5" id="polygon"></textarea>
        <div class="error" v-if="error">{{ error }}</div>

        <label for="color">Цвет полигона</label>
        <input type="color" class="input" v-model="color" id="color">

        <label for="width">Ширина линии</label>
        <input type="number" class="input" v-model="width" id="width" min="1" max="10">

        <label for="comment">Комментарий</label>
        <input type="text" class="input" v-model="comment" id="comment">

        <Button
          class="button"
          type="stroke"
          text="Добавить"
          :disabled="!polygon || !color || !width || width < 1 || width > 10"
          @click="addPolygon"
        />
      </div>

      <br>
      <q-checkbox v-model="isDraw" label="Нарисовать на карте" color="accent"  />
      <div class="draw" v-if="isDraw">
        <label for="drawPolygon">Полигон</label>
        <textarea v-model="drawPolygon" rows="5" id="drawPolygon"></textarea>

        <Button
          class="button"
          type="stroke"
          text="Geojson"
          @click="showGeojson"
        />
      </div>

      <div class="buttons" v-if="isDraw">
        <button
          type="button"
          title="Нарисовать"
          @click="stateMapdraw = stateMapdraw !== 'edit' ? 'edit' : ''"
          :class="{ active : stateMapdraw === 'edit' }"
        >
          <EditPolygonIcon :width="20" :height="20" />
        </button>
        <button
          type="button"
          title="Вырезать"
          :class="{ active : stateMapdraw === 'cut' }"
          :disabled="!drawnPolygons"
          @click="stateMapdraw = stateMapdraw !== 'cut' ? 'cut' : ''"
        >
          <CutPolygonIcon :width="20" :height="20" />
        </button>
        <button
          type="button"
          title="Удалить"
          :disabled="!drawnPolygons"
          @click="deleteAll"
        >
          <DeletePolygonIcon :width="20" :height="20" />
        </button>
      </div>
    </q-scroll-area>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import * as Turf from '@turf/turf'
import difference from '@turf/difference'
import gjv from 'geojson-validation'
import Button from '@/components/uikit/buttons/Button'
import EditPolygonIcon from '@/components/uikit/icons/EditPolygonIcon'
import CutPolygonIcon from '@/components/uikit/icons/CutPolygonIcon'
import DeletePolygonIcon from '@/components/uikit/icons/DeletePolygonIcon'

const store = useStore()
const props = defineProps(['map', 'mapDraw'])

const drawnPolygons = ref(null)
const drawPolygon = ref(null)
const polygons = ref([])
const polygon = ref(null)
const error = ref('')
const color = ref('#00ff00')
const comment = ref('')
const width = ref(1)
const stateMapdraw = ref(null) // ['edit', 'cut']
const isDraw = ref(false)

const maintenanceNotice = computed(() => store.state.settings.maintenanceNotice)

const deleteAll = () => {
  props.mapDraw.deleteAll()
  drawnPolygons.value = null
  stateMapdraw.value = ''
}

const findPolygon = (id) => {
  const currentZoom = props.map.getZoom()
  const polygon = polygons.value[id]
  props.map.fitBounds(Turf.bbox(polygon), { maxZoom: currentZoom ? currentZoom : 11 })
}

const hidePolygon = (id) => {
  const hiddenPolygon = polygons.value[id]
  hiddenPolygon.properties.isHidden = true
  polygons.value = polygons.value.slice()
}

const showPolygon = (id) => {
  const shownPolygon = polygons.value[id]
  shownPolygon.properties.isHidden = false
  polygons.value = polygons.value.slice()
}

const deletePolygon = (id) => {
  polygons.value = [...polygons.value.slice(0, id), ...polygons.value.slice(id + 1, polygons.value.length)]
}

const addPolygon = () => {
  if (validatePolygon(polygon.value)) {
    polygons.value = [ ...polygons.value, {
      id: polygons.value.length + 1,
      comment: comment.value,
      type: 'Feature',
      geometry: JSON.parse(polygon.value),
      properties: {
        color: color.value,
        width: width.value && width.value > 0 && width.value < 11 ? width.value : 1,
        radius: width.value && width.value > 0 && width.value < 11 ? width.value : 1,
        isHidden: false
      },
    } ]
    polygon.value = null
    comment.value = ''
    color.value = '#00ff00'
  }
}

const setStyle = () => {
  const options = ['case']

  polygons.value.forEach((item) => {
    options.push(['==', ['get', 'color'], item.properties.color])
    const color = item.properties.color ? item.properties.color : 'blue'
    options.push(color)
  })

  const widthOptions = ['case']

  polygons.value.forEach((item) => {
    widthOptions.push(['==', ['get', 'width'], item.properties.width])
    const width = item.properties.width ? Number(item.properties.width) : 1
    widthOptions.push(width)
  })

  const radiusOptions = ['case']

  polygons.value.forEach((item) => {
    radiusOptions.push(['==', ['get', 'radius'], item.properties.radius])
    const radius = item.properties.radius ? Number(item.properties.radius) : 1
    radiusOptions.push(radius)
  })

  props.map.setPaintProperty('polygons_fill','fill-color', [...options, 'blue'])
  props.map.setPaintProperty('polygons_line','line-color', [...options, 'blue'])
  props.map.setPaintProperty('polygons_line','line-width', [...widthOptions, 1])
  props.map.setPaintProperty('point','circle-color', [...options, 'blue'])
  props.map.setPaintProperty('point','circle-radius', [...radiusOptions, 1])
}

const addLayer = () => {
  const polygonsArray = polygons.value.filter((polygon) => !polygon.properties.isHidden)
  if (polygonsArray.length > 0) {
    const source = {
      type: 'FeatureCollection',
      features: polygonsArray
    }

    if (!props.map.getSource('polygons_data')) {
      props.map.addSource('polygons_data', {
        type: 'geojson',
        data: source
      })
    }

    props.map.addLayer({
      id: 'polygons_fill',
      type: 'fill',
      source: 'polygons_data',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'fill-color': ['case',
          ['boolean', ['feature-state', 'active'], false], 'red',
          'blue'
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'active'], false], 0.5,
          ['boolean', ['feature-state', 'hover'], false], 0.4,
          0.2,
        ],
      },
    })

    props.map.addLayer({
      id: 'polygons_line',
      type: 'line',
      source: 'polygons_data',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'line-color': ['case',
          ['boolean', ['feature-state', 'active'], false], 'red',
          ['boolean', ['feature-state', 'edited'], false], 'transparent',
          ['boolean', ['feature-state', 'focus'], false], 'red',
          'blue'
        ],
        'line-width': 1
      },
    })

    props.map.addLayer({
      id: 'point',
      type: 'circle',
      source: 'polygons_data',
      layout: {
        'visibility': 'visible'
      },
      paint: {
        'circle-color': 'blue',
        'circle-radius': 5
      },
    })

    setStyle()
  }
}

const removeLayer = () => {
  if (props.map.getSource('polygons_data')) {
    props.map.removeLayer('polygons_fill')
    props.map.removeLayer('polygons_line')
    props.map.removeLayer('point')
    props.map.removeSource('polygons_data')
  }
}

const showGeojson = () => {
  drawPolygon.value = JSON.stringify(drawnPolygons.value.geometry)
}

const updatePolygon = (event) => {
  const allPolygon = props.mapDraw.getAll().features
  if (event.type !== 'draw.delete') {
    if (allPolygon.length > 1) {
      drawnPolygons.value = difference(allPolygon[0], allPolygon[1])
      props.mapDraw.deleteAll()
      props.mapDraw.add(drawnPolygons.value)
    } else {
      drawnPolygons.value = allPolygon[0]
    }

    if (event.type !== 'draw.create') {
      props.mapDraw.changeMode('direct_select', {
        featureId: allPolygon[0].id
          ? allPolygon[0].id
          : drawnPolygons.value.id,
      })
    }
  } else {
    props.mapDraw.deleteAll().getAll()
    drawnPolygons.value = null
  }
}

const validatePolygon = (polygon) => {
  try {
    error.value = ''
    const geoJson = JSON.parse(polygon)
    const isValid = gjv.valid(geoJson)
    error.value = isValid ? '' : 'Невалидная геометрия'
    return isValid
  } catch (error) {
    error.value = 'Невалидная геометрия'
    return false
  }
}

onMounted(() => {
  props.map.on('draw.create', updatePolygon)
  props.map.on('draw.delete', updatePolygon)
  props.map.on('draw.update', updatePolygon)
})

onBeforeUnmount(() => {
  removeLayer()
  props.mapDraw.deleteAll()
  props.mapDraw.changeMode('static')
  props.map.off('draw.create', updatePolygon)
  props.map.off('draw.delete', updatePolygon)
  props.map.off('draw.update', updatePolygon)
})

watch(polygons, () => {
  removeLayer()
  addLayer()
})

watch(stateMapdraw, (value) => {
  if (value !== 'cut' || value !== 'edit') {
    props.mapDraw.changeMode('static')
  }

  if (value === 'cut') {
    props.mapDraw.changeMode('draw_polygon')
    return
  }

  if (value === 'edit') {
    const ids = props.mapDraw.getAll().features.map((feature) => feature.id)

    if (ids[0]) {
      props.mapDraw.changeMode('direct_select', { featureId: ids[0] })
    } else {
      props.mapDraw.changeMode('draw_polygon')
    }
  }
})

watch(isDraw, (value) => {
  if (!value) {
    stateMapdraw.value = ''
    drawPolygon.value = null
    drawnPolygons.value = null
    props.mapDraw.deleteAll()
    props.mapDraw.changeMode('static')
  }
})
</script>

<style lang="scss" scoped>
.container {
  width: 344px;
  height: 100%;
  padding: 20px;
  background-color: white;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  border-left: 1px solid $color-gray3;
  background-color: #F4F7FA;
  transition: left 0.3s ease-in-out;
}

.polygon {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

.polygon .button {
  padding: 0;
  background-color: transparent;
  margin-left: 5px;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.polygon span {
  margin-right: auto;
}

textarea {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  margin-bottom: 10px;
}

.color {
  margin-right: 5px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.error {
  color: red;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  margin-top: 20px;
  gap: 8px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    min-width: 44px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 2px solid $color-gray4;
    cursor: pointer;

    &.active {
      border-color: $color-active;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
</style>
