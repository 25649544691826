// import Vue from 'vue'
import api from '@/api'
import ApiService from '@/services'
import * as Turf from '@turf/turf'
import { fixGeometryCollection } from '../utils/map/fix-geometry-collection'

const actions = {
  fetchOverlaps({ commit }, hash) { // не используется
    commit('setOverlaps', [])
    return new Promise((resolve, reject) => {
      api.post('/private/sections/overlaps', { hashes: [hash] })
        .then((response) => {
          commit('setOverlaps', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchSection({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get(`/private/sections/${id}`)
        .then((response) => {
          commit('setSection', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchSectionHistory({ commit }, id) {
    api.get(`/private/sections/${id}/history`)
      .then((response) => {
        commit('setHistory', response.data)
      })
  },

  fetchPolygon({ commit }, params) {
    commit('setPolygon', {})
    return new Promise((resolve, reject) => {
      ApiService.map.getGeoPolygon(params.id)
        .then((response) => {
          let geojson = response.data.geojson
          if (response.data?.geojson?.type === 'GeometryCollection') {
            geojson = fixGeometryCollection(response.data?.geojson)
          }

          if (response.data && response.data?.geojson && response.data?.hash && response.data?.layer && response.data?.id) {
            commit('setPolygon', {
              type: 'Feature',
              geometry: geojson,
              properties: {
                hash: response.data.hash,
                layer: response.data.layer,
                name: response.data.layer,
                id: response.data.id
              }
            })
          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchFiles({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.map.getFiles(params)
        .then((response)=> {
          commit('setFiles', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchImages({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.map.getImages(params)
        .then((response)=> {
          commit('setImages', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // eslint-disable-next-line
  addRectangles({ commit }, params) {
    return new Promise((resolve, reject) => {
      api.post(`/private/sections/${params.id}/polygon`, params)
        .then((response) => {
          commit('setSection', response.data)
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
    })
  },

  editPolygon({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.map.updatePolygon(params)
        .then((response) => {
          commit('setSection', response.data)
          resolve(response.data)
        })
        .catch(() => {
          reject()
        })
    })
  },
}

const mutations = {
  setSection(state, data){
    state.currentSection = data
  },
  setReviewer(state, data) {
    state.currentSection = data
  },
  setHistory(state, data) {
    state.history = data
  },
  setImages(state, data) {
    state.images = data
  },
  setFiles(state, data) {
    state.files = data
  },
  setPolygon(state, data) {
    state.polygon = data
  },
  setOverlaps(state, data) {
    state.overlaps = data
  },
  setIsCountChangeWidthDetails(state) {
    state.isCountChangeWidthDetails += 1
  },
}

const state = () => ({
  currentSection: null,
  history: [],
  images: [],
  files: [],
  polygon: {},
  overlaps: null,
  isCountChangeWidthDetails: 0
})

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
